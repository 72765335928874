import { Route, Routes, useNavigate } from 'react-router-dom';
import {
  SUPER_ADMIN,
  SUPER_ADMIN_APPOINTMENTS,
  SUPER_ADMIN_LATEST_TESTS,
  SUPER_ADMIN_PATIENTS,
  SUPER_ADMIN_PROVIDERS,
  SUPER_ADMIN_TESTS,
} from 'utilities/routes';
import { DnaVisitLatestTests } from '../pages/DnaVisitLatestTests';
import ButtonComponent from 'components/button/buttonComponent';
import { Tests } from 'app/superadmin/pages/Tests';
import { DataProvider } from 'apollo/DataProvider';
import { Roles } from '../../../firebase/interfaces';
import { Appointments } from 'app/superadmin/pages/Appointments';
import { Patients } from 'app/superadmin/pages/Patients';
import { Providers } from 'app/superadmin/pages/Providers';
import Loader from '../../../components/loaderComponent';

export const SuperAdminRoutes = () => {
  const navigate = useNavigate();
  return (
    <>
      <>
        {/* <ButtonComponent
          onClick={() => navigate(`${SUPER_ADMIN}${SUPER_ADMIN_LATEST_TESTS}`)}
        >
          Latest Tests
        </ButtonComponent> */}
        <ButtonComponent
          onClick={() => navigate(`${SUPER_ADMIN}${SUPER_ADMIN_TESTS}`)}
        >
          Tests
        </ButtonComponent>
        <ButtonComponent
          onClick={() => navigate(`${SUPER_ADMIN}${SUPER_ADMIN_PATIENTS}`)}
        >
          Patients
        </ButtonComponent>
        <ButtonComponent
          onClick={() => navigate(`${SUPER_ADMIN}${SUPER_ADMIN_PROVIDERS}`)}
        >
          Providers
        </ButtonComponent>
        <ButtonComponent
          onClick={() => navigate(`${SUPER_ADMIN}${SUPER_ADMIN_APPOINTMENTS}`)}
        >
          Appointments
        </ButtonComponent>
      </>

      <DataProvider role={Roles.SUPERADMIN}>
        <Routes>
          <Route
            path={SUPER_ADMIN_LATEST_TESTS}
            element={<DnaVisitLatestTests />}
          />
          <Route path={SUPER_ADMIN_TESTS} element={<Tests />} />
          <Route
            path={`${SUPER_ADMIN_TESTS}/provider/:providerFhirId`}
            element={<Tests />}
          />
          <Route
            path={`${SUPER_ADMIN_TESTS}/patient/:patientFhird`}
            element={<Tests />}
          />
          <Route
            path={`${SUPER_ADMIN_TESTS}/provider/:providerFhirId/types/:types`}
            element={<Tests />}
          />
          <Route
            path={`${SUPER_ADMIN_TESTS}/patient/:patientFhird/types/:types`}
            element={<Tests />}
          />
          <Route
            path={`${SUPER_ADMIN_TESTS}/types/:types`}
            element={<Tests />}
          />
          <Route path={SUPER_ADMIN_PATIENTS} element={<Patients />} />
          <Route path={SUPER_ADMIN_PROVIDERS} element={<Providers />} />
          <Route path={SUPER_ADMIN_APPOINTMENTS} element={<Appointments />} />
          <Route
            path={`${SUPER_ADMIN_APPOINTMENTS}/provider/:providerFhirId`}
            element={<Appointments />}
          />
          <Route
            path={`${SUPER_ADMIN_APPOINTMENTS}/patient/:patientFhird`}
            element={<Appointments />}
          />
          <Route
            path={`${SUPER_ADMIN_APPOINTMENTS}/provider/:providerFhirId/status/:statuses`}
            element={<Appointments />}
          />
          <Route
            path={`${SUPER_ADMIN_APPOINTMENTS}/patient/:patientFhird/status/:statuses`}
            element={<Appointments />}
          />
          <Route
            path={`${SUPER_ADMIN_APPOINTMENTS}/status/:status`}
            element={<Appointments />}
          />

          <Route path="/dashboard" element={<Loader />} />
        </Routes>
      </DataProvider>
    </>
  );
};
