import React from 'react';
import Select from 'react-select';
import { OrderByDirections } from 'graphql/generated/remote-schema-hasura';
import { NullIndicatorSeparator } from 'components/dropdown/ReactSelectComponents';

export interface ISingleValueLabel {
  readonly value: string;
  readonly label?: string | null;
}

interface ISortValue {
  order: OrderByDirections;
  searchCriteria: string;
}

interface ISortDropdownProps {
  defaultValue?: ISortValue;
  handleChange: (value: ISortValue) => void;
}

const convertDefaultValue = (sortValue: ISortValue) => {
  return JSON.stringify(sortValue);
};

const options: ISingleValueLabel[] = [
  {
    value: convertDefaultValue({
      order: OrderByDirections.Asc,
      searchCriteria: 'NAME',
    }),
    label: 'Name A-Z',
  },
  {
    value: convertDefaultValue({
      order: OrderByDirections.Desc,
      searchCriteria: 'NAME',
    }),
    label: 'Name Z-A',
  },
];

const SortDropdown: React.FC<ISortDropdownProps> = ({
  handleChange,
  defaultValue = JSON.parse(options[0].value),
}) => {
  const onChange = (option: ISingleValueLabel | null) => {
    const { order, searchCriteria } = JSON.parse(
      String(option?.value),
    ) as ISortValue;
    return handleChange({ order, searchCriteria });
  };

  const getOptionByValue = (sortValue: ISortValue | undefined) => {
    if (!sortValue) {
      return options[0];
    }
    return options.find(
      (option) => option.value === convertDefaultValue(sortValue),
    );
  };

  return (
    <>
      <Select
        options={options}
        onChange={onChange}
        defaultValue={getOptionByValue(defaultValue)}
        components={{
          IndicatorSeparator: NullIndicatorSeparator,
        }}
      />
    </>
  );
};

export default SortDropdown;
