import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import Loader from 'components/loaderComponent';
import { useContext } from 'react';
import { AuthContext, UserData } from 'auth/context/AuthContext';
import { Roles } from '../../../firebase/interfaces';
import { ProviderDashboardPage } from '../modules/ProviderDashboard';
import { PatientDashboardPage } from '../modules/PatientDashboard';
import { useNavigate } from 'react-router-dom';
import { SUPER_ADMIN } from '../../../utilities/routes';

export const DashboardPage = () => {
  const navigate = useNavigate();
  const { user: loggedUser } = useContext(AuthContext);

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.DASHBOARD,
  });

  const getDashboardComponent = (loggedUser: UserData | null) => {
    switch (loggedUser?.role) {
      case Roles.PROVIDER:
        return <ProviderDashboardPage />;
      case Roles.PATIENT:
        return <PatientDashboardPage />;
      case Roles.SUPERADMIN:
        navigate(SUPER_ADMIN);
        return <Loader />;
      default:
        return <Loader />;
    }
  };

  if (loading || !locale) return <Loader />;

  return <>{getDashboardComponent(loggedUser)}</>;
};
