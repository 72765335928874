import {
  components,
  DropdownIndicatorProps,
  OptionProps,
  PlaceholderProps,
} from 'react-select';
import { appointmentRenderInfo } from '../../my-appointments/interfaces';
import { AppointmentTypeEnum } from 'graphql/generated/hasura';
import { twMerge } from 'tailwind-merge';
import { ReactComponent as Arrow } from 'assets/icons/upArrow.svg';
import React from 'react';

export interface ISingleValueAppointmentProps {
  value: string;
  label?: string | null;
  type?: AppointmentTypeEnum | null;
}

export const Option = (
  props: OptionProps<ISingleValueAppointmentProps, true>,
) => {
  const { icon: Icon, label } =
    appointmentRenderInfo[props.data.type as AppointmentTypeEnum] || {};
  return (
    <components.Option
      {...props}
      className={twMerge('flex ', props.isSelected && '!bg-light-teal')}
    >
      <Icon className="inline mr-2" />
      <span className="text-sm font-semibold !text-med-gray">
        {props.children}
      </span>
      <span className="text-sm font-semibold !text-med-gray ml-1">{label}</span>
    </components.Option>
  );
};

export const Placeholder = (
  props: PlaceholderProps<ISingleValueAppointmentProps>,
) => {
  return (
    <components.Placeholder
      {...props}
      className="text-sm italic font-normal !text-black opacity-50"
    >
      {props.children}
    </components.Placeholder>
  );
};

export const DropdownIndicatorSingle = (
  props: DropdownIndicatorProps<ISingleValueAppointmentProps, true>,
) => {
  return (
    <components.DropdownIndicator {...props}>
      <Arrow
        className={twMerge(
          'h-1.5',
          props.isFocused ? 'rotate-0' : 'rotate-180',
        )}
      />
    </components.DropdownIndicator>
  );
};
