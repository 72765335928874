import { Tab } from 'components/tabs/tabs.interface';
import TabsComponent from 'components/tabs/tabsComponent';
import { useGetPage } from 'hooks/useGetPage';
import { useContext, useEffect, useState } from 'react';
import { pageIds } from 'utilities/constants';
import { interpolateVariables } from 'utilities/functions';
import { NOTIFICATIONS, NOTIFICATIONS_UNREAD_BASE } from 'utilities/routes';
import { AuthContext } from 'auth/context/AuthContext';
import { Roles } from '../../../firebase/interfaces';
import { useNotificationCount } from 'notifications/contexts/NotificationsContext';
import { DoseSpotNotificationsModal } from 'app/notifications/pages/DoseSpotNotificationsModal';
import { useModal } from 'layout/useModal';
import {
  NotificationResult,
  useNotificationsResult,
} from 'hooks/useNotifications';

interface NotificationsHeaderProps {
  userNotificationsQuery: useNotificationsResult;
  fetchMore: () => void;
}

export const NotificationsHeader: React.FC<NotificationsHeaderProps> = ({
  userNotificationsQuery,
}) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.NOTIFICATIONS,
  });
  const { notifications: notificationsData, loading: loadingNotifications } =
    userNotificationsQuery;
  const [countText, setCountText] = useState<string>('');

  const { user } = useContext(AuthContext);

  const { externalNotificationCount } = useNotificationCount();

  const dosespotNotificationLabel = `${externalNotificationCount} - ${locale?.doseSpotNotifications}`;

  useEffect(() => {
    const populateCountText = (notifications: NotificationResult) => {
      const current = notifications.notifications.length;
      const total = notifications.total;
      if (current && total) {
        setCountText(
          interpolateVariables(locale?.count, {
            current: String(current),
            total: String(total),
          }),
        );
      }
    };

    if (notificationsData) {
      populateCountText(notificationsData);
    }
  }, [notificationsData, locale]);

  const patientTabs: Tab[] = [
    {
      name: locale?.all,
      url: NOTIFICATIONS,
    },
    {
      name: locale?.unread,
      url: `${NOTIFICATIONS}${NOTIFICATIONS_UNREAD_BASE}`,
    },
  ];
  const [openDoseSpotNotificationsModal] = useModal(DoseSpotNotificationsModal);

  const providerTabs: Tab[] = [
    {
      name: locale?.all,
      url: NOTIFICATIONS,
    },
    {
      name: locale?.unread,
      url: `${NOTIFICATIONS}${NOTIFICATIONS_UNREAD_BASE}`,
    },
    {
      name: dosespotNotificationLabel,
      url: 'urn:codex:notifications:dosespot:provider',
      onClick: () => openDoseSpotNotificationsModal(undefined),
      customState: { showNotifications: true },
    },
  ];

  const getTabsBasedOnRole = () => {
    switch (user?.role) {
      case Roles.PATIENT:
        return patientTabs;
      case Roles.PROVIDER:
        if (externalNotificationCount >= 1) {
          return providerTabs;
        } else {
          return providerTabs.filter(
            (tab) => tab.name !== dosespotNotificationLabel,
          );
        }
        break;
      default:
        return patientTabs;
    }
  };

  if ((loading && !locale) || loadingNotifications) return null;
  return (
    <>
      <div className="flex flex-col desktop:flex-row w-full justify-between items-center p-0 px-7 pt-[30px] desktop:pt-0 gap-[30px] desktop:mb-[34px]">
        <div className="flex flex-row  w-full justify-between items-center gap-[7px]">
          <h1 className="text-left text-h2 text-dark-gray font-medium desktop:text-h2">
            {locale.title}
          </h1>
        </div>
      </div>
      <div className="flex items-center flex-col w-full desktop:flex-row desktop:justify-between desktop:relative">
        <TabsComponent tabs={getTabsBasedOnRole()} isNotification />
        <div className="text-med-gray mb-3 desktop:mb-11 font-semibold">
          {countText}
        </div>
      </div>
    </>
  );
};
