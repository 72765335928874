import Avatar from 'components/avatarComponent';
import { AddressInformationOutput, ProviderOutput } from './ProviderCardProps';
import { appointmentRenderInfo } from 'app/my-appointments/interfaces';
import ButtonComponent from 'components/button/buttonComponent';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import { useGetSignedUrlFromStorage } from 'hooks/useGetSignedUrlFromStorage';
import { useMemo } from 'react';
import { ProviderEducationOutput } from '../../../../graphql/generated/hasura';

export interface ProviderStackProps {
  provider: ProviderOutput;
  isFirstItem?: boolean;
  onSelect: (
    providerCodexId: string,
    calendarId?: string | null,
    ownerId?: string | null,
  ) => void;
}

const DEFAULT_EDUCATION_STATE = {
  residency: '',
  undergraduate: '',
  medicalSchool: '',
} as ProviderEducationOutput;

const getAddress = (address: AddressInformationOutput | undefined) => {
  if (!address) {
    return undefined;
  }
  const { city, address1, state } = address;
  return `${address1}, ${city}, ${state}`;
};

const ProviderStack: React.FC<ProviderStackProps> = ({
  provider,
  isFirstItem = false,
  onSelect,
}) => {
  const { data: locale } = useGetPage({
    locale: 'en',
    pageId: pageIds.SELECT_A_PROVIDER,
  });

  const signedProfileUrl = useGetSignedUrlFromStorage(
    provider.SENSITIVE_profile_picture_id ?? '',
    provider.id ?? '',
  );

  const education = useMemo(
    () => provider?.SENSITIVE_education ?? [DEFAULT_EDUCATION_STATE],
    [provider],
  );

  if (!locale) return null;

  return (
    <div
      className={`flex flex-col w-full py-4 px-8 gap-4 ${
        !isFirstItem && 'desktop:border-l'
      } border-b border-gray-opacity-15`}
    >
      <div className="flex flex-col desktop:flex-row w-full items-center justify-center my-2">
        <div
          className={
            'flex desktop:flex-row flex-col w-full items-center desktop:items-start gap-8'
          }
        >
          <div className="flex w-52 justify-center">
            <Avatar size="s" imageUrl={signedProfileUrl} />
          </div>
          <div className="flex flex-col w-full items-start gap-2">
            <p className="font-semibold text-neutral-800 text-h5 underline text-center">
              {provider.SENSITIVE_firstname} {provider.SENSITIVE_lastname}
            </p>
            <p className="text-med-gray-3 text-h6 font-normal text-left">
              {`${education[0]?.undergraduate} - ${education[0]?.residency}`}
            </p>
            <div className="w-full flex flex-wrap my-4 gap-2">
              {provider.SENSITIVE_credentials.titles.map((specialty, key) => (
                <div
                  key={key}
                  className="bg-light-blue rounded-[30px] py-1 px-3"
                >
                  <p className="text-neutral-800 text-h7 font-semibold text-center">
                    {specialty}
                  </p>
                </div>
              ))}
            </div>
            <p className="text-med-gray-3 text-h6 font-normal text-left">
              {getAddress(provider.SENSITIVE_address_information)}
            </p>
          </div>
          <div className="flex justify-start desktop:justify-end">
            <div className="flex flex-col w-full desktop:w-auto items-start desktop:items-center gap-2">
              <p className="w-max text-neutral-800 uppercase text-h7 font-bold text-left">
                {locale?.acceptedAppointments}
              </p>
              <div className="flex flex-col w-full justify-center gap-1">
                {provider.accepted_appointment_types.map((serviceOffering) => {
                  const Icon =
                    appointmentRenderInfo[serviceOffering.serviceType].icon;
                  return (
                    <p
                      key={serviceOffering.serviceType}
                      className="text-med-gray-3 text-h7 font-semibold flex flex-row items-center gap-2"
                    >
                      <span>
                        <Icon />
                      </span>
                      {
                        locale[
                          appointmentRenderInfo[serviceOffering.serviceType]
                            .text
                        ]
                      }{' '}
                      (${serviceOffering.price})
                    </p>
                  );
                })}
                <ButtonComponent
                  className="font-exo font-bold text-sm mt-6"
                  onClick={() =>
                    onSelect(
                      provider.id,
                      provider.acuityCalendarId,
                      provider.acuityOwnerId,
                    )
                  }
                >
                  {locale.select}
                </ButtonComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderStack;
