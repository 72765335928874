import React, { useCallback, useState } from 'react';
import Modal from 'components/modal/modalComponent';
import { useGetPage } from 'hooks/useGetPage';
import { genericActionsIds, pageIds } from 'utilities/constants';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { PatientInfo } from '../interfaces/patient.interface';
import Alert from 'components/alertComponent';
import { sanitize } from 'utilities/sanitizer';
import DatePicker from 'components/datePicker/DatePicker';
import Select from 'react-select';
import { NullIndicatorSeparator } from 'components/dropdown/ReactSelectComponents';
import {
  DropdownIndicatorSingle,
  ISingleValueAppointmentProps,
  Option,
  Placeholder,
} from './SelectComponentreportModal';
import InputComponent from 'components/inputComponent';
import { reportOptions, ReportVariablesEnum } from '../const/reportVariables';

interface IReportPatientModalProps extends PatientInfo {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  appointments: ISingleValueAppointmentProps[];
}

const termsUpdater = (str: string, find: string) => {
  if (!find) return str;
  const re = new RegExp(find.replace(/[.*+\-?^${}()[\]\\]/g, '\\$&'), 'gi');
  return str.replace(
    re,
    '<span class="underline cursor-pointer font-bold text-clc-blue">$&</span>',
  );
};

const RequiredStar = () => {
  return (
    <span className="text-base ml-0.5 font-bold text-alert-negative">*</span>
  );
};

const ReportPatientModal: React.FC<IReportPatientModalProps> = ({
  isOpen,
  setOpen,
  name,
  appointments,
}) => {
  const handleClose = () => setOpen(false);
  const handleSubmit = useCallback(() => setOpen(false), [setOpen]);
  const [reportTypes, setReportTypes] = useState<ReportVariablesEnum[]>([]);
  const [description, setDescription] = useState<string | undefined>(undefined);

  const handleCheckboxChange = (type: ReportVariablesEnum) => {
    return setReportTypes((newSelectedTypes) => {
      if (newSelectedTypes.includes(type)) {
        return newSelectedTypes.filter((t) => t !== type);
      }
      return [...newSelectedTypes, type];
    });
  };

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.REPORT_PATIENT_MODAL,
  });

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.SUBMIT, genericActionsIds.CANCEL],
    });

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.SUBMIT]?.submit,
      onClick: handleSubmit,
      className: 'font-exo',
    },
    {
      label: genericAction?.[genericActionsIds.CANCEL]?.cancel,
      type: 'underline',
      className: 'font-exo',
      onClick: handleClose,
    },
  ];

  if ((loading && !locale) || (genericActionLoading && !genericAction)) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      buttons={Buttons}
      title={`${locale?.title} ${name}`}
      modalDesktopWidth="max-w-[1280px]"
    >
      <div className="grid desktop:grid-cols-3 gap-8">
        <div className="col-span-1">
          <Alert
            type="info-blue"
            text={locale?.notification}
            textClassName="text-med-gray-3 text-start"
            desktopItemsAlign="!items-start !flex-row"
            iconClassName="!w-6 !h-6"
          />

          <p className="font-medium text-base text-med-gray-3 mt-8">
            {locale?.paragraph1}
          </p>

          <p
            className="font-medium text-base text-med-gray-3 mt-8"
            dangerouslySetInnerHTML={{
              __html: sanitize(
                termsUpdater(String(locale?.paragraph2), String(locale?.terms)),
              ),
            }}
          />

          <h6 className="mt-8 text-base text-charcoal-gray font-semibold">
            {locale?.paragraph3Title}
          </h6>
          <p className="font-medium text-base text-med-gray-3">
            {locale?.paragraph3}
          </p>
        </div>
        <div className="col-span-2">
          <div className="desktop:flex items-center">
            <h6 className="desktop:mb-0 desktop:mr-4 mb-4 text-base desktop:text-right text-charcoal-gray font-semibold desktop:w-56">
              {locale?.dataTitles}
              <RequiredStar />
            </h6>
            <DatePicker onChange={console.log} />
          </div>

          <div className="flex w-full flex-col">
            <h6 className="mt-8 mb-4 text-base text-charcoal-gray font-semibold">
              {locale?.appointmentsTitle}
            </h6>

            <Select
              options={appointments}
              placeholder={locale?.appointmentsPlaceholder}
              components={{
                DropdownIndicator: DropdownIndicatorSingle,
                IndicatorSeparator: NullIndicatorSeparator,
                Placeholder,
                Option,
              }}
            />

            <div className="mt-8">
              <div className="flex items-baseline">
                <h6 className="text-base text-charcoal-gray font-semibold">
                  {locale?.whyReporting}
                  <RequiredStar />
                </h6>
                <span className="ml-2 text-sm text-med-gray-3 font-semibold">
                  {locale?.selectAll}
                </span>
              </div>

              <div className="relative flex flex-col gap-4 mt-4">
                {reportOptions.map(({ strapi, value }) => {
                  return (
                    <InputComponent
                      key={strapi}
                      name={strapi}
                      type="checkbox"
                      checkboxProps={{
                        checked: reportTypes.includes(value),
                        onCheckboxChange: () => handleCheckboxChange(value),
                        checkboxLabel: locale?.reportingType[strapi],
                        checkboxLabelClassName:
                          'text-sm text-med-gray-3 font-semibold',
                      }}
                    />
                  );
                })}

                <div className="flex ml-16 flex-col items-center w-auto gap-4 desktop:flex-row desktop:w-full desktop:absolute bottom-0 right-0">
                  <span className="w-full text-charcoal-gray font-semibold text-base desktop:text-right">
                    {locale?.reportingType?.otherDescription}
                  </span>
                  <InputComponent
                    type="text"
                    name="otherDescription"
                    noMarginBottom
                  />
                </div>
              </div>
            </div>

            <div className="mt-8">
              <h6 className="text-base text-charcoal-gray font-semibold">
                {locale?.descriptionsTitle}
                <RequiredStar />
              </h6>

              <InputComponent
                testID="myNotes"
                type="text-area"
                name="description"
                value={description}
                textAreaProps={{
                  height: 'h-full min-h-[120px]',
                  containerHeight: 'h-full',
                  onTextAreaChange: (e) => {
                    setDescription(e.target.value);
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReportPatientModal;
