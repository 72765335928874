export enum ReportVariablesEnum {
  FAKE,
  SCAM,
  ADULT,
  SELLING,
  SUICIDE,
  VIOLENT,
  BULLING,
  OTHER,
}

const reportStrapiKeys = {
  [ReportVariablesEnum.FAKE]: 'fake',
  [ReportVariablesEnum.SCAM]: 'scam',
  [ReportVariablesEnum.ADULT]: 'adult',
  [ReportVariablesEnum.OTHER]: 'other',
  [ReportVariablesEnum.SELLING]: 'selling',
  [ReportVariablesEnum.SUICIDE]: 'suicide',
  [ReportVariablesEnum.VIOLENT]: 'violent',
  [ReportVariablesEnum.BULLING]: 'bullying',
};

export const reportOptions = [
  {
    value: ReportVariablesEnum.BULLING,
    strapi: reportStrapiKeys[ReportVariablesEnum.BULLING],
  },
  {
    value: ReportVariablesEnum.SUICIDE,
    strapi: reportStrapiKeys[ReportVariablesEnum.SUICIDE],
  },
  {
    value: ReportVariablesEnum.VIOLENT,
    strapi: reportStrapiKeys[ReportVariablesEnum.VIOLENT],
  },
  {
    value: ReportVariablesEnum.SELLING,
    strapi: reportStrapiKeys[ReportVariablesEnum.SELLING],
  },
  {
    value: ReportVariablesEnum.ADULT,
    strapi: reportStrapiKeys[ReportVariablesEnum.ADULT],
  },
  {
    value: ReportVariablesEnum.SCAM,
    strapi: reportStrapiKeys[ReportVariablesEnum.SCAM],
  },
  {
    value: ReportVariablesEnum.FAKE,
    strapi: reportStrapiKeys[ReportVariablesEnum.FAKE],
  },
  {
    value: ReportVariablesEnum.OTHER,
    strapi: reportStrapiKeys[ReportVariablesEnum.OTHER],
  },
];
