import { Navigate, Route, Routes } from 'react-router-dom';
import { LegalDocument } from 'legalDocument/LegalDocument';
import PageLayout from 'layout/PageLayout';
import NetworkRequiredRoutes from 'components/networkRequiredRoutes';

export const LegalDocumentRoutes = () => {
  return (
    <PageLayout className="bg-light-blue">
      <NetworkRequiredRoutes>
        <Routes>
          <Route path="/:id" element={<LegalDocument />} />
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      </NetworkRequiredRoutes>
    </PageLayout>
  );
};
