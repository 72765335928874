import { NpiInfo } from 'auth/pages/SignUpProvider/components/NpiInfo';
import { ProviderModuleInterfaceProps } from './interfaces/providerModuleInterfaces';
import AlertComponent from 'components/alertComponent';
import { ReactComponent as CloseIcon } from 'assets/icons/iconClose.svg';
import TabsComponent from 'components/tabs/tabsComponent';
import {
  MY_ACCOUNT_PROFILE,
  MY_PROFILE_ACCOUNT_SETTINGS,
} from 'utilities/routes';
import { Tab } from 'components/tabs/tabs.interface';
import { useGetPage } from 'hooks/useGetPage';
import { genericActionsIds, pageIds } from 'utilities/constants';
import Loader from 'components/loaderComponent';
import { SidebarComponent } from 'components/sidebar/sidebarComponent';
import { SidebarItem } from 'components/sidebar/sidebar.interface';
import { AccountInfo } from '../providerComponents/accountInfo/AccountInfo';
import { ProviderProfileDataOptional } from 'app/my-account/interfaces/profile.interfaces';
import ButtonComponent from 'components/button/buttonComponent';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { PersonalInfo } from '../providerComponents/personalInfo/PersonalInfo';
import { CredentialsAndCertifications } from '../providerComponents/credentialsAndCertifications/CredentialsAndCertifications';
import { ContactInfo } from '../providerComponents/contactInfo/ContactInfo';
import NavigateBlockModal from '../../../../../../components/NavigateBlockModal';
import { useContext, useMemo } from 'react';
import { AuthContext } from 'auth/context/AuthContext';

export const ProviderModule: React.FC<ProviderModuleInterfaceProps> = ({
  isNpiInfoOpen,
  alertProps,
  setAlertProps,
  currentSidebarItem,
  setCurrentSidebarItem,
  providerProfileData,
  handleUpdatedProviderAccountInfo,
  handleProviderProfileInfoChange,
  submitting,
  displayMFAForm,
  handleVerifyMFACode,
  handleOnSubmit,
  isBlockLocation,
}) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PROFILE,
  });

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.UPDATE, genericActionsIds.VERIFY],
    });

  const { user } = useContext(AuthContext);

  const ProviderTabs: Tab[] = useMemo(() => {
    const tabs = [
      {
        name: locale?.title,
        url: MY_ACCOUNT_PROFILE,
      },
      {
        name: locale?.providerLocales?.accountSettings?.title,
        url: MY_PROFILE_ACCOUNT_SETTINGS,
      },
    ];

    return user?.providerEnabled ? tabs : tabs.slice(0, 1);
  }, [user, locale]);

  const providerSidebarItems: SidebarItem[] = [
    { name: locale?.providerLocales?.accountInfoSection },
    { name: locale?.providerLocales?.personalInfoSection },
    { name: locale?.providerLocales?.credentialsAndCertificationsSection },
    { name: locale?.providerLocales?.contactInfoSection },
  ];

  const handleOnClick = (item: string) => {
    setAlertProps(null);
    setCurrentSidebarItem(item);
  };

  if (loading || !locale || genericActionLoading) {
    return <Loader />;
  }

  return (
    <>
      {isNpiInfoOpen && <NpiInfo />}
      <div className="flex flex-col">
        {alertProps && (
          <div className="flex flex-row mb-[30px] px-7">
            <AlertComponent
              type={alertProps.type}
              customContent={
                <div className="flex flex-col w-full desktop:flex-row justify-between items-center desktop:relative">
                  <div className="flex flex-col desktop:flex-row w-full gap-5 desktop:gap-[30px]">
                    <p
                      className={`text-base font-semibold ${
                        alertProps.type === 'positive'
                          ? 'text-alert-positive'
                          : 'text-alert-negative'
                      } text-center desktop:mr-[35px]`}
                    >
                      {alertProps.text}
                    </p>
                  </div>

                  <CloseIcon
                    className="absolute top-5 right-5 desktop:top-0 desktop:right-0 w-6 h-6 stroke-med-gray cursor-pointer"
                    onClick={() => setAlertProps(null)}
                  />
                </div>
              }
            />
          </div>
        )}

        <TabsComponent tabs={ProviderTabs} />
        <div className="flex flex-col gap-[30px] px-7">
          <div className="flex flex-col gap-[30px] desktop:flex-row desktop:items-center desktop:justify-between">
            <h1 className="text-h2 desktop:text-h1 text-dark-gray">
              {locale?.title}
            </h1>
            <p className="text-sm text-clc-red font-bold">
              {locale?.requiredText}
            </p>
          </div>

          <div className="flex flex-row items-start desktop:pb-[60px]">
            <SidebarComponent
              items={providerSidebarItems}
              currentItem={currentSidebarItem}
              onClick={handleOnClick}
            />
            <div className="flex flex-col items-start w-full gap-2.5 desktop:gap-5">
              <div
                className={`w-full ${
                  currentSidebarItem ===
                  locale?.providerLocales?.accountInfoSection
                    ? 'desktop:flex'
                    : 'desktop:hidden'
                }`}
              >
                <AccountInfo
                  {...(providerProfileData as ProviderProfileDataOptional)}
                  handleUpdatedProviderAccountInfo={
                    handleUpdatedProviderAccountInfo
                  }
                  handleProviderProfileInfoChange={
                    handleProviderProfileInfoChange
                  }
                />
              </div>

              <div className="flex flex-col items-start w-full gap-2.5 desktop:gap-5">
                <div
                  className={`w-full ${
                    currentSidebarItem ===
                    locale?.providerLocales?.personalInfoSection
                      ? 'desktop:flex'
                      : 'desktop:hidden'
                  }`}
                >
                  <PersonalInfo
                    {...(providerProfileData as ProviderProfileDataOptional)}
                    handleUpdatedProviderAccountInfo={
                      handleUpdatedProviderAccountInfo
                    }
                    handleProviderProfileInfoChange={
                      handleProviderProfileInfoChange
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col items-start w-full gap-2.5 desktop:gap-5">
                <div
                  className={`w-full ${
                    currentSidebarItem ===
                    locale?.providerLocales?.credentialsAndCertificationsSection
                      ? 'desktop:flex'
                      : 'desktop:hidden'
                  }`}
                >
                  <CredentialsAndCertifications
                    {...(providerProfileData as ProviderProfileDataOptional)}
                    handleUpdatedProviderAccountInfo={
                      handleUpdatedProviderAccountInfo
                    }
                    handleProviderProfileInfoChange={
                      handleProviderProfileInfoChange
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col items-start w-full gap-2.5 desktop:gap-5">
                <div
                  className={`w-full ${
                    currentSidebarItem ===
                    locale?.providerLocales?.contactInfoSection
                      ? 'desktop:flex'
                      : 'desktop:hidden'
                  }`}
                >
                  <ContactInfo
                    {...(providerProfileData as ProviderProfileDataOptional)}
                    handleUpdatedProviderContactInfo={
                      handleUpdatedProviderAccountInfo
                    }
                    handleProviderProfileInfoChange={
                      handleProviderProfileInfoChange
                    }
                  />
                </div>
              </div>

              <div className="desktop:hidden w-full flex justify-end mt-2.5">
                <ButtonComponent
                  iconPosition="right"
                  className="px-10"
                  disabled={submitting}
                  onClick={() =>
                    !submitting &&
                    (displayMFAForm
                      ? handleVerifyMFACode(true)
                      : handleOnSubmit())
                  }
                >
                  {displayMFAForm
                    ? genericAction?.[genericActionsIds.VERIFY].verify
                    : genericAction?.[genericActionsIds.UPDATE].update}
                </ButtonComponent>
              </div>
            </div>
          </div>
        </div>
      </div>

      <NavigateBlockModal isBlock={isBlockLocation} />
    </>
  );
};
