import Avatar from 'components/avatarComponent';
import { ReactComponent as SendMessageIcon } from 'assets/icons/sendMessage.svg';
import { ReactComponent as PrescribeIcon } from 'assets/icons/prescribe.svg';
import { ReactComponent as ReferProviderIcon } from 'assets/icons/referProvider.svg';
import { ReactComponent as PdfFileIcon } from 'assets/icons/pdfFile.svg';
import { ReactComponent as ReportIcon } from 'assets/icons/messageReport.svg';
import { ReactComponent as BlockPatient } from 'assets/icons/patientStatuses/patientBlock.svg';
import { ReactComponent as WhiteCheckedProfileIcon } from 'assets/icons/white-checked-profile.svg';
import ButtonComponent from 'components/button/buttonComponent';
import { PatientHeaderProps } from '../interfaces/patient.interface';
import {
  capitalizeFirstLetter,
  formatDateForAppointment,
} from 'utilities/functions';
import { VirtualPharmaciesModal } from './VirtualPharmaciesModal';
import { useModal } from 'layout/useModal';
import React, { useMemo, useState } from 'react';
import OptionsModal from 'components/optionsModal/OptionsModal';
import ReportPatientModal from './ReportPatientModal';
import { useGetAppointmentsByProviderQuery } from '../../../graphql/generated/remote-schema-hasura';
import { AppointmentLimit } from '../pages/PatientInfo';

export const PatientHeader: React.FC<PatientHeaderProps> = ({
  patientCodexId,
  age,
  city,
  gender,
  name,
  profileImgSrc,
  state,
  kyc_approved,
  locale,
  showN4Buttons,
}) => {
  const [openVirtualPharmaciesModal] = useModal(VirtualPharmaciesModal);
  const [opened, setOpened] = useState<boolean>(false);
  const [isOpenReport, setOpenReportModal] = useState<boolean>(false);

  const options = useMemo(
    () => [
      {
        Icon: BlockPatient,
        label: 'Block patient',
        onClick: () => ({}),
      },
      {
        Icon: ReportIcon,
        label: 'Report patient',
        onClick: () => setOpenReportModal(true),
      },
    ],
    [],
  );

  const now = useMemo(() => new Date().toISOString(), []);
  const oneMonthFromNow = useMemo(() => {
    const currentDate = new Date();
    const futureDate = new Date(currentDate);
    futureDate.setMonth(currentDate.getMonth() - 1);
    return futureDate.toISOString();
  }, []);

  const { data: finishAppointments } = useGetAppointmentsByProviderQuery({
    fetchPolicy: 'network-only',
    variables: {
      patientCodexId: patientCodexId || '',
      from: oneMonthFromNow,
      to: now,
      limit: AppointmentLimit,
      sort: 'start',
      sortDirection: 1,
    },
    onCompleted: (data) => {
      return data.getFHIRAppointmentByRequestProviderId.appointment || [];
    },
  });

  const appointments = useMemo(() => {
    const app =
      finishAppointments?.getFHIRAppointmentByRequestProviderId.appointment ||
      [];
    return app.map(({ appointmentCodexId, start, appointmentType }) => ({
      value: appointmentCodexId,
      label: formatDateForAppointment(new Date(String(start)), true),
      type: appointmentType,
    }));
  }, [finishAppointments]);

  return (
    <div className="flex flex-col desktop:flex-row items-center gap-[15px] desktop:gap-[30px]">
      <Avatar size="md" imageUrl={profileImgSrc} />
      <div className="flex flex-col items-center desktop:items-start gap-[15px] desktop:gap-2.5">
        <div className="flex flex-col desktop:flex-row items-center gap-y-[15px] gap-x-2">
          <div className="flex items-center text-charcoal-gray text-[28px] font-medium font-exo leading-[38px] capitalize">
            <span>{name}</span>
            {kyc_approved && (
              <WhiteCheckedProfileIcon className="w-[18px] h-[18px]" />
            )}
          </div>
          <OptionsModal
            opened={opened}
            setOpened={setOpened}
            options={options}
          />
        </div>
        <div className="pb-2 desktop:pb-0 justify-center items-center gap-2.5 flex">
          <span className="text-med-gray text-base font-semibold leading-snug">
            {capitalizeFirstLetter(gender)}
          </span>
          <div className="w-1.5 justify-center items-center flex">
            <div className="w-1.5 h-1.5 bg-neutral-400 rounded-full" />
          </div>
          <span className="text-med-gray text-base font-semibold leading-snug">
            {age} {locale.yearsOld}
          </span>
          <div className="w-1.5 justify-center items-center flex">
            <div className="w-1.5 h-1.5 bg-neutral-400 rounded-full" />
          </div>
          <span className="text-med-gray text-base font-semibold leading-snug">
            {city}, {state}
          </span>
        </div>
        <div className="flex flex-col desktop:flex-row items-center gap-5 desktop:gap-[60px] self-stretch">
          {showN4Buttons && (
            <div className="justify-center items-center flex self-stretch">
              <ButtonComponent
                Icon={SendMessageIcon}
                iconPosition="left"
                paddingX="px-5"
                paddingY="py-2"
                className="uppercase font-bold font-exo text-sm"
                borderRadius="rounded-[5px]"
                stroke="stroke-none"
              >
                {locale.sendMessage}
              </ButtonComponent>
            </div>
          )}
          <ButtonComponent
            type="outlined-transparent"
            className="border-none active:bg-transparent active:underline active:text-clc-blue hover:bg-transparent hover:underline hover:text-clc-blue"
            onClick={() =>
              openVirtualPharmaciesModal({ patientId: patientCodexId }, true)
            }
          >
            <PrescribeIcon />
            <span>{locale.prescribe}</span>
          </ButtonComponent>
          {showN4Buttons && (
            <div className="justify-center items-center gap-[7px] inline-flex border-[1px] border-transparent cursor-pointer active:border-b-clc-blue">
              <div className="justify-center items-center flex">
                <ReferProviderIcon />
              </div>
              <div className="text-clc-blue text-base font-bold leading-snug">
                {locale.referProvider}
              </div>
            </div>
          )}
          <div className="justify-center items-center gap-[7px] inline-flex border-[1px] border-transparent cursor-pointer active:border-b-clc-blue">
            <div className="justify-center items-center flex">
              <PdfFileIcon />
            </div>
            <div className="text-clc-blue uppercase text-base font-bold leading-snug">
              {locale.patientHealthHistory}
            </div>
          </div>
        </div>
      </div>

      <ReportPatientModal
        isOpen={isOpenReport}
        setOpen={setOpenReportModal}
        patientCodexId={patientCodexId}
        age={age}
        city={city}
        gender={gender}
        name={name}
        state={state}
        appointments={appointments}
      />
    </div>
  );
};
