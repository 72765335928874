import { useNotifications as NotificationContext } from '../notifications/contexts/NotificationsContext';
import { useNotifications } from 'hooks/useNotifications';
import { User_Notification_Status_Enum } from '../graphql/generated/hasura';
import { useEffect } from 'react';

export const useNotificationProvider = () => {
  const { setNotificationCount } = NotificationContext();

  const { notifications: notifications, loading } = useNotifications({
    limit: 10,
    includeStatuses: User_Notification_Status_Enum.Read,
  });

  const { notifications: notificationsUnread, loading: loadingUnread } =
    useNotifications({
      includeStatuses: User_Notification_Status_Enum.Delivered,
      limit: 10,
    });

  useEffect(() => {
    setNotificationCount({
      external: notifications?.total ?? 0,
      internal: notificationsUnread?.total ?? 0,
    });
  }, [
    loadingUnread,
    loading,
    setNotificationCount,
    notifications,
    notificationsUnread,
  ]);
};
