import { useNavigate } from 'react-router-dom';

import {
  MY_PATIENTS,
  MY_PATIENTS_BIO_INFO,
  MY_PATIENTS_BIO_INFO_PATIENT_INFO,
  PROVIDERS_PROVIDER_BIO,
} from 'utilities/routes';
import Avatar from 'components/avatarComponent';
import { useGetSignedUrlFromStorage } from 'hooks/useGetSignedUrlFromStorage';
import ButtonComponent from 'components/button/buttonComponent';
import { useIsMobile } from 'hooks/useIsMobile';
import { Roles } from '../../firebase/interfaces';

type Role = Roles.PROVIDER | Roles.PATIENT;

interface ILastVisitedUserItem {
  userId: string;
  name: string;
  title?: string;
  profileImage?: string | null;
  signUrl?: boolean;
  buttonText: string;
  role: Role;
}

const LastVisitedUserItem = ({
  name,
  userId,
  profileImage,
  title,
  signUrl,
  buttonText,
  role,
}: ILastVisitedUserItem) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const onProviderRedirect = () => {
    navigate(PROVIDERS_PROVIDER_BIO, { state: { providerId: userId } });
  };

  const handleViewFullPatientProfile = () => {
    navigate(
      `${MY_PATIENTS}/${userId}/${MY_PATIENTS_BIO_INFO}/${MY_PATIENTS_BIO_INFO_PATIENT_INFO}`,
      { state: { patientId: userId } },
    );
  };

  const ActionMap = {
    [Roles.PROVIDER]: onProviderRedirect,
    [Roles.PATIENT]: handleViewFullPatientProfile,
  };

  const signedProfileUrl = useGetSignedUrlFromStorage(
    profileImage ?? '',
    userId ?? '',
  );

  return (
    <div className="flex gap-2.5 desktop:gap-5 items-center">
      <Avatar
        size={isMobile ? 'sm_s' : 'sm_md'}
        imageUrl={signUrl ? signedProfileUrl : profileImage}
      />
      <div className="flex flex-col flex-grow">
        <p
          className="font-semibold text-charcoal-gray text-h6 desktop:text-h5 underline cursor-pointer"
          onClick={ActionMap[role]}
        >
          {name}
        </p>
        <p className="text-med-gray text-sm desktop:text-h6 font-medium">
          {title}
        </p>
      </div>

      <ButtonComponent
        onClick={ActionMap[role]}
        className="hidden desktop:block text-sm font-exo font-bold"
        type="underline"
      >
        {buttonText}
      </ButtonComponent>
    </div>
  );
};

export default LastVisitedUserItem;
