import { useEffect } from 'react';
import { ReactComponent as Arrow } from 'assets/icons/arrowUp.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetLegalDocument } from 'hooks/legalDocuments/useGetLegalDocument';
import { useIsMobile } from 'hooks/useIsMobile';
import { BASE_ROUTE } from 'utilities/routes';
import { sanitize } from 'utilities/sanitizer';

export const LegalDocument = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { legalDocument } = useGetLegalDocument({ legalDocumentId: id });

  useEffect(() => {
    if (legalDocument === null) {
      navigate(BASE_ROUTE);
    }
  }, [legalDocument, navigate]);

  const handleBackToTop = () =>
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

  if (legalDocument?.legalDocumentId) {
    return (
      <div className="flex-wrap px-2.5 desktop:px-[30px]">
        <div
          id="legalDocument"
          className="overflow-x-auto"
          dangerouslySetInnerHTML={{
            __html: sanitize(
              isMobile ? legalDocument.contentMobile : legalDocument.contentWeb,
            ),
          }}
        />

        <div className="flex mt-12 w-[1050px] mx-auto relative bg-gray-opacity-15 h-[1px]">
          <button
            onClick={handleBackToTop}
            className="flex items-center absolute top-0 left-1/2 -translate-y-1/2 -translate-x-1/2 bg-white px-4 rounded-full"
          >
            <span className="text-med-gray-3 font-medium">Back to top</span>
            <Arrow className="ml-1.5 stroke-med-gray-3" />
          </button>
        </div>
      </div>
    );
  }

  return null;
};
