import { ReactComponent as ChevronRight } from '../../../assets/icons/chevron-right.svg';
import { ReactComponent as CheckActive } from '../../../assets/icons/checkActive.svg';
import { DynamicQuestionnaireSidebarListProps } from '../interfaces/dynamicQuestionnaireSidebar.interface';
import { InBetweenOptionLine } from './inBetweenOptionLine';

export const DynamicQuestionnaireSidebarList = ({
  items,
  currentItem,
  isSidebarBlocked,
  onClick,
}: DynamicQuestionnaireSidebarListProps) => {
  const generateItemNumber = (value: number) => {
    const shiftedValue = value + 1;

    if (shiftedValue < 10) {
      return `0${shiftedValue}`;
    }
    return shiftedValue;
  };

  return (
    <>
      {items.map((item, key) => (
        <ul
          dir="ltr"
          id={item.name}
          onClick={isSidebarBlocked ? () => null : () => onClick(item.name)}
          className={`${currentItem === item.name ? 'desktop:bg-white' : ''} ${
            isSidebarBlocked ? 'cursor-not-allowed' : 'cursor-pointer'
          } ${
            items.length !== key + 1 ? 'w-full' : ''
          } flex justify-between items-center desktop:pl-5 desktop:pr-2.5 rounded-s-[10px] bg-transparent p-0`}
          key={item.name}
        >
          <div className="flex items-center">
            <span
              id={`circle-${key}${item.complete ? '-complete' : ''}`}
              className={`${
                item.complete ? 'bg-clc-blue' : 'bg-white'
              } circle text-link flex items-center justify-center font-bold w-[25px] h-[25px] rounded-[15px] pt-[1px] shrink-0`}
            >
              {item.complete ? (
                <CheckActive width="10px" color="#fff" />
              ) : (
                generateItemNumber(key)
              )}
            </span>
            <li className="p-5 desktop:flex hidden">{item.name}</li>
          </div>
          <InBetweenOptionLine
            optionsList={items}
            currentMapIndex={key}
            currentItem={item}
          />
          <div
            className={`${
              currentItem === item.name ? 'desktop:flex' : 'hidden'
            } hidden`}
          >
            <ChevronRight style={{ width: '10px', height: '15px' }} />
          </div>
        </ul>
      ))}
    </>
  );
};
