import { useCallback, useEffect, useState } from 'react';
import {
  useGetFileUrlFromStorageMutation,
  useGetSignUrlFromStorageForRequesterMutation,
  useGetSignedUrlFromStorageByAdminMutation,
} from 'graphql/generated/hasura';

export const useGetSignedUrlFromStorage = (
  imageId: string | undefined,
  userId?: string | undefined,
): string | undefined => {
  const [getFileUrlFromStorage] = useGetFileUrlFromStorageMutation({});
  const [getSignedUrlFromStorageByAdmin] =
    useGetSignUrlFromStorageForRequesterMutation({});
  const [signedUrl, setSignedUrl] = useState<string>();

  useEffect(() => {
    if (imageId) {
      (async () => {
        let parsedImageId = imageId;
        if (parsedImageId.includes('http')) {
          parsedImageId = (parsedImageId.split('/').pop() || '').split('?')[0];
        }
        let resolvedUrl;
        if (userId) {
          const queryResult = await getSignedUrlFromStorageByAdmin({
            variables: { requestedUserId: userId, fileId: parsedImageId },
          });
          resolvedUrl =
            queryResult.data?.GetSignUrlFromStorageForRequester?.url;
        } else {
          const queryResult = await getFileUrlFromStorage({
            variables: { fileId: parsedImageId },
          });
          resolvedUrl = queryResult.data?.GetSignUrlFormStorage?.url;
        }
        setSignedUrl(resolvedUrl);
      })();
    }
  }, [getFileUrlFromStorage, getSignedUrlFromStorageByAdmin, imageId, userId]);

  return signedUrl;
};

export const useGetSignedUrlFromStorageFunction = (): ((
  imageId: string,
  userId?: string | undefined,
) => Promise<string | undefined>) => {
  const [getFileUrlFromStorage] = useGetFileUrlFromStorageMutation({});
  const [getSignedUrlFromStorageByAdmin] =
    useGetSignedUrlFromStorageByAdminMutation({});

  const getSignedUrlFromStorage = useCallback(
    async (
      imageId: string,
      userId?: string | undefined,
    ): Promise<string | undefined> => {
      let parsedImageId = imageId;
      if (parsedImageId.includes('http')) {
        parsedImageId = parsedImageId.split('/').pop() || '';
      }
      let resolvedUrl;
      if (userId) {
        const queryResult = await getSignedUrlFromStorageByAdmin({
          variables: { userId: userId, fileId: parsedImageId },
        });
        resolvedUrl = queryResult.data?.GetSignUrlFromStorageByAdmin?.url;
      } else {
        const queryResult = await getFileUrlFromStorage({
          variables: { fileId: parsedImageId },
        });
        resolvedUrl = queryResult.data?.GetSignUrlFormStorage?.url;
      }
      return resolvedUrl;
    },
    [getFileUrlFromStorage, getSignedUrlFromStorageByAdmin],
  );
  return getSignedUrlFromStorage;
};
