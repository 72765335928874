export enum MyProviderTabIds {
  myProviders = 'my-providers',
  pastProviders = 'past-providers',
  findAProvider = 'find-a-provider',
}

export enum GenderFilterEnum {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
  UNKNOWN = 'unknown',
}

export enum AcceptedAppointmentsFilterEnum {
  VIDEO = 'video',
  CHAT = 'chat',
}
