import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext, UserData } from './AuthContext';
import {
  PhoneMultiFactorInfo,
  MultiFactorResolver,
  User,
  signOut,
} from 'firebase/auth';
import {
  Roles,
  SignInProvider,
  UserHasuraClaims,
} from '../../firebase/interfaces';
import {
  DEFAULT_PRIVATE_LD_ATTRIBUTES,
  LOCAL_STORAGE_USER_FROM_QR,
} from 'utilities/constants';
import { firebaseAuth } from '../../firebase/config';
import {
  useMFA,
  useFetchUserByEmail,
  useFetchUserFromHasuraOrFhir,
  userHasCustomClaimsAndRole,
} from '../../firebase/hooks';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import {
  clearLocalStorageExceptKeys,
  replacePlusSignFromDisplayedName,
} from 'utilities/functions';
import { CodexFeatureFlagsKinds } from 'utilities/interfaces';
import { LOCAL_STORAGE_WEATHER_ZIP } from 'hooks/useWeather/constants';
import StorageService from '../../utilities/storageService';
import {
  ALLOWED_ROLES_CUSTOM_CLAIM,
  HASURA_USER_STORAGE_KEY,
  JWT_CUSTOM_CLAIMS,
} from '../../firebase/constants';

interface AuthProviderProps {
  children: React.ReactNode;
}

const hasuraUserStorage = new StorageService(HASURA_USER_STORAGE_KEY);

let isFirstRendered = false;
const grStore = new StorageService(LOCAL_STORAGE_USER_FROM_QR);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<UserData | null>(null);
  const [profilePicture, setProfilePictureResolver] = useState<string | null>(
    null,
  );
  const [mfaResolver, setResolver] = useState<MultiFactorResolver | null>(null);
  const [isAuthResolved, setAuthResolved] = useState(false);
  const { fetchUserByEmailPromise } = useFetchUserByEmail();
  const { fetchUserDataFromDifferentSource, fetchProvidersByPatientId } =
    useFetchUserFromHasuraOrFhir();
  const { getEnrolledFactors } = useMFA();
  const navigate = useNavigate();
  const ldClient = useLDClient();

  const login = useCallback(
    (redirect?: string) => {
      const user = firebaseAuth.currentUser;
      if (user && user.emailVerified) {
        const enrolledFactors = getEnrolledFactors();
        if (!enrolledFactors || enrolledFactors.length === 0) {
          redirect && navigate(redirect, { state: { email: user.email } });
        } else {
          const enrolledFactor = enrolledFactors[0] as PhoneMultiFactorInfo;
          setMFAPhoneNumber(enrolledFactor.phoneNumber);
          setLoggedIn(true);
        }
      }
      setAuthResolved(true);
    },
    [getEnrolledFactors, navigate],
  );

  const loginWithOutMFA = useCallback(
    (redirect?: string) => {
      const user = firebaseAuth.currentUser;
      if (user && user.emailVerified) {
        setLoggedIn(true);
        redirect && navigate(redirect);
      }
      setAuthResolved(true);
    },
    [navigate],
  );

  const logout = useCallback(async () => {
    hasuraUserStorage.clear();
    setLoggedIn(false);
    setUser(null);
    setAuthResolved(true);
    setProfilePicture(null);
    setMfaResolver(null);
    setMFAPhoneNumber('');
    setPhoneNumber('');
    clearLocalStorageExceptKeys([LOCAL_STORAGE_WEATHER_ZIP]);
    signOut(firebaseAuth);
  }, []);

  useEffect(() => {
    if (!isFirstRendered) {
      isFirstRendered = true;
      firebaseAuth.onAuthStateChanged(async (currentUser: User | null) => {
        try {
          if (currentUser && currentUser.email) {
            const { signInProvider, claims } =
              await currentUser.getIdTokenResult();
            const customClaims = claims[JWT_CUSTOM_CLAIMS] as UserHasuraClaims;
            const allowedRoles = customClaims[ALLOWED_ROLES_CUSTOM_CLAIM] || [];

            const userData = {
              email: currentUser.email,
              displayName: replacePlusSignFromDisplayedName(
                currentUser.displayName,
              ),
              signInProvider: signInProvider as SignInProvider,
              role:
                allowedRoles.length > 0
                  ? (allowedRoles[allowedRoles.length - 1] as Roles)
                  : Roles.PATIENT,
            };
            setUser(userData);
            const { hasCustomClaims, role } =
              await userHasCustomClaimsAndRole();
            if (hasCustomClaims) {
              const user = await fetchUserByEmailPromise(currentUser.email);
              setUserUuid(user.id);

              const userDataFromSource = await fetchUserDataFromDifferentSource(
                currentUser.email || '',
                role as Roles,
              );

              const providerId = await fetchProvidersByPatientId(
                user.id,
                role as Roles,
              );

              hasuraUserStorage.setData({
                ...user,
                ...userDataFromSource,
                providerId,
              });

              await ldClient?.identify({
                kind: CodexFeatureFlagsKinds.patient,
                key: user.id,
                email: currentUser.email,
                privateAttributeNames: DEFAULT_PRIVATE_LD_ATTRIBUTES,
              });
              if (
                userDataFromSource.role &&
                userDataFromSource.role === Roles.PROVIDER
              ) {
                setUser((userData) => {
                  return {
                    ...userData,
                    providerEnabled: userDataFromSource?.enabled,
                    role: userDataFromSource?.role,
                  } as UserData;
                });
                if (userDataFromSource?.enabled !== undefined) {
                  setUser((userData) => {
                    return {
                      ...userData,
                      providerEnabled: userDataFromSource?.enabled,
                    } as UserData;
                  });
                }
                if (userDataFromSource.SENSITIVE_firstname) {
                  setUser((userData) => {
                    return {
                      ...userData,
                      displayName: userDataFromSource?.SENSITIVE_firstname,
                    } as UserData;
                  });
                }
                if (userDataFromSource.SENSITIVE_phone) {
                  setPhoneNumber(userDataFromSource.SENSITIVE_phone);
                }
              } else if (
                userDataFromSource.role &&
                userDataFromSource.role === Roles.PATIENT
              ) {
                setUser((userData) => {
                  return {
                    ...userData,
                    providerId,
                    role: userDataFromSource?.role,
                  } as UserData;
                });

                if (userDataFromSource.SENSITIVE_firstname) {
                  setUser((userData) => {
                    return {
                      ...userData,
                      displayName: userDataFromSource?.SENSITIVE_firstname,
                    } as UserData;
                  });
                }

                if (userDataFromSource.SENSITIVE_phone) {
                  setPhoneNumber(userDataFromSource.SENSITIVE_phone);
                }

                if (
                  'SENSITIVE_country' in userDataFromSource &&
                  userDataFromSource.SENSITIVE_country
                ) {
                  setCountry(userDataFromSource.SENSITIVE_country);
                }
              }

              if (userDataFromSource.SENSITIVE_profile_picture_id) {
                setUser((userData) => {
                  return {
                    ...userData,
                    profilePicture:
                      userDataFromSource?.SENSITIVE_profile_picture_id || null,
                  } as UserData;
                });
              }

              grStore.setData('false');

              if (process.env.REACT_APP_IS_DEBUG_MODE) {
                loginWithOutMFA();
              } else {
                login();
              }
            }
          } else {
            grStore.clear();
            logout();
          }
        } catch (error) {
          console.error(error);
          setAuthResolved(true);
        }
      });
    }
  }, [
    ldClient,
    fetchUserByEmailPromise,
    login,
    logout,
    fetchUserDataFromDifferentSource,
    loginWithOutMFA,
    fetchProvidersByPatientId,
  ]);

  const setUserUuid = (uuid: string) => {
    setUser((user) => {
      return {
        ...user,
        uuid,
      } as UserData;
    });
  };

  const setMFAPhoneNumber = (mfaPhoneNumber: string) => {
    setUser((user) => {
      return {
        ...user,
        mfaPhoneNumber,
      } as UserData;
    });
  };

  const setPhoneNumber = (phoneNumber: string) => {
    setUser((user) => {
      return {
        ...user,
        phoneNumber,
      } as UserData;
    });
  };

  const setCountry = (country: string) => {
    setUser((user) => {
      return {
        ...user,
        country,
      } as UserData;
    });
  };

  const setMfaResolver = (mfaResolver: MultiFactorResolver | null) =>
    setResolver(mfaResolver);

  const setProfilePicture = (profilePicture: string | null) =>
    setProfilePictureResolver(profilePicture);

  const setRole = (role: Roles) => {
    if (!user) return;
    if (user.role === role) return;
    setUser({
      ...user,
      role,
    });
  };

  const authContextValue = {
    isLoggedIn,
    user,
    mfaResolver,
    isAuthResolved,
    profilePicture,
    setRole,
    setUserUuid,
    setPhoneNumber,
    setMFAPhoneNumber,
    setCountry,
    setMfaResolver,
    setProfilePicture,
    login,
    loginWithOutMFA,
    logout,
  };

  return (
    <>
      {!!isAuthResolved && (
        <AuthContext.Provider value={authContextValue}>
          {children}
        </AuthContext.Provider>
      )}
    </>
  );
};
