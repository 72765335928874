import Alert from 'components/alertComponent';
import Loader from 'components/loaderComponent';
import { useGetPage } from 'hooks/useGetPage';
import { AllowedFormats, ImageFormat, pageIds } from 'utilities/constants';
import ButtonComponent from 'components/button/buttonComponent';
import { Persona_Inquiry_Status_Enum } from 'graphql/generated/hasura';
import { ProviderPersonaSection } from './components/ProviderPersonaSection/ProviderPersonaSection';
import {
  MY_ACCOUNT_PROFILE,
  MY_PROFILE_VALIDATE_PERSONA,
} from 'utilities/routes';
import { NavLink, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from 'auth/context/AuthContext';
import useFhirProviderDataLoader from 'hooks/useFhirProviderDataLoader';

import { capitalizeFirstLetter, parsePhoneNumber } from 'utilities/functions';
import { ReactComponent as ArrowSeeMore } from 'assets/icons/arrowLoadMore.svg';
import { ReactComponent as ArrowSeeLess } from 'assets/icons/arrowUp.svg';
import { PhoneDataItem } from './components/PhoneDataItem/PhoneDataItem';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CodexFeatureFlags } from '../../../../utilities/interfaces';
import useUserVerificationStatus, {
  UserVerificationStatus,
} from 'hooks/useUserVerificationStatus';
import { Roles } from '../../../../firebase/interfaces';
import Avatar from 'components/avatarComponent';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as EditIconDisable } from 'assets/icons/editDisable.svg';
import {
  useGetFileUrlFromStorageMutation,
  useUpdateProfilePictureMutation,
} from 'graphql/generated/hasura';
import { convertFileToBase64 } from 'utilities/functions';
import { useModalParams } from 'components/modal/useModalManager';
import { ValidatePersonaModal } from '../my-profile/components/personaComponents/ValidatePersonaModal';

export const ProviderPendingApproval: React.FC = () => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { isOpen: isValidatePersonaOpen } = useModalParams('validate-persona');

  const {
    user: loggedUser,
    profilePicture,
    setProfilePicture,
  } = useContext(AuthContext);
  const { enablePersonaVerification } = useFlags<CodexFeatureFlags>();
  const { userVerificationValidation, loading: loadingUserVerificationStatus } =
    useUserVerificationStatus();
  const [userVerificationStatus, setUserVerificationStatus] =
    useState<UserVerificationStatus>({
      status: null,
    });
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [updateProfilePictureMutation] = useUpdateProfilePictureMutation({});
  const [getFileUrlFromStorage] = useGetFileUrlFromStorageMutation({});

  const { fhirProviderDataLoading, providerProfileData } =
    useFhirProviderDataLoader(loggedUser, loggedUser?.email);

  const handleSetExpanded = (value: boolean) => {
    setIsExpanded(value);
  };

  const { data: localePendingReview, loading: loadingPendingReview } =
    useGetPage({
      locale: 'en',
      pageId: pageIds.PROVIDER_USER_PENDING_REVIEW,
    });

  const { data: localeMyProfile, loading: loadingMyProfile } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PROFILE,
  });

  const providerParsedPhone = parsePhoneNumber(
    providerProfileData?.phone ?? '',
  );
  const officeParsedPhone = parsePhoneNumber(
    providerProfileData?.office_information?.phone ?? '',
  );

  const handleUploadPhoto = () => {
    if (fileInputRef.current && !isEditing) {
      fileInputRef.current.click();
    }
  };

  const validateImage = (image: File): Promise<void> => {
    if (!AllowedFormats.includes(image.type as ImageFormat)) {
      return Promise.reject();
    }

    const img = new Image();
    img.src = URL.createObjectURL(image);

    return new Promise((resolve) => {
      img.onload = () => {
        setIsEditing(false);
        resolve();
      };
    });
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (isEditing) return;
    const file = event.target.files && event.target.files[0];

    if (file) {
      try {
        await validateImage(file);
        setIsEditing(true);

        const base64Image = await convertFileToBase64(file);
        const profileResponse = await updateProfilePictureMutation({
          variables: {
            image: base64Image,
            filename: file.name,
          },
        });
        const image = await getFileUrlFromStorage({
          variables: {
            fileId: profileResponse.data?.UpdateProfilePicture?.fileId || '',
          },
        });

        setProfilePicture(image.data?.GetSignUrlFormStorage?.url || null);
        setIsEditing(false);
      } catch (error) {
        setIsEditing(false);
        error &&
          console.error(`Error on image upload ${JSON.stringify(error)}`);
      }
    }
  };

  useEffect(() => {
    if (enablePersonaVerification && loggedUser?.role === Roles.PROVIDER) {
      const validateUserStatus = async () => {
        const { userVerificationStatus } = await userVerificationValidation();
        setUserVerificationStatus({ status: userVerificationStatus });
      };
      validateUserStatus().catch((e) =>
        console.log(
          'An error happened while validating the user verification status',
          e,
        ),
      );
    }
  }, [enablePersonaVerification, loggedUser?.role, userVerificationValidation]);

  const accountInfoItems = [
    {
      label: localePendingReview?.profilePicture,
    },
    {
      label: localePendingReview?.firstName,
      value: providerProfileData?.firstname,
    },
    {
      label: localePendingReview?.lastName,
      value: providerProfileData?.lastname,
    },
    {
      label: localePendingReview?.emailAddress,
      value: providerProfileData?.email,
    },
    {
      label: localePendingReview?.phone,
      value: `${providerProfileData?.address_information?.country} ${localePendingReview?.ext} ${providerProfileData?.phone}`,
    },
    {
      label: localePendingReview?.NPI,
      value: providerProfileData?.nationalProviderIdentifier,
    },
  ];

  const personalInfoItems = [
    {
      label: localePendingReview?.gender,
      value: capitalizeFirstLetter(providerProfileData?.gender as string),
    },
    {
      label: localePendingReview?.languages,
      value: providerProfileData?.languagesOfCommunication,
    },
    {
      label: localePendingReview?.aboutYou,
      value: isExpanded
        ? providerProfileData?.about
        : (providerProfileData?.about as string)?.length > 450
        ? `${providerProfileData?.about?.substring(0, 450)}...`
        : providerProfileData?.about,
    },
  ];

  const credentialsAndCertificationsItems = [
    {
      label: localePendingReview?.titles,
      value: providerProfileData?.credentials?.titles,
    },
    {
      label: localePendingReview?.statesLicensedForPractice,
      value: providerProfileData?.credentials?.states,
    },
    {
      label: localePendingReview?.boardCertifications,
      value: providerProfileData?.credentials?.certifications,
    },
    {
      label: localePendingReview?.medicalSchool,
      value: providerProfileData?.education?.map(
        (educationItem) => educationItem.medicalSchool,
      ),
    },
    {
      label: localePendingReview?.residency,
      value: providerProfileData?.education?.map(
        (educationItem) => educationItem.residency,
      ),
    },
    {
      label: localePendingReview?.undergraduate,
      value: providerProfileData?.education?.map(
        (educationItem) => educationItem.undergraduate,
      ),
    },
  ];

  const officeDetailsItems = [
    {
      label: localePendingReview?.officeOrganizationName,
      value: providerProfileData?.office_information?.officeName,
    },
    {
      label: localePendingReview?.emailAddress,
      value: providerProfileData?.office_information?.email,
    },
    {
      label: localePendingReview?.phone,
      value: `${providerProfileData?.office_information?.country} ${localePendingReview?.ext} ${providerProfileData?.office_information?.phone}`,
    },
    {
      label: localePendingReview?.address,
      value: [
        providerProfileData?.office_information?.address1,
        providerProfileData?.office_information?.address2,
      ],
    },
  ];

  if (
    loadingPendingReview ||
    loadingMyProfile ||
    fhirProviderDataLoading ||
    !localePendingReview ||
    !localeMyProfile ||
    loadingUserVerificationStatus
  )
    return <Loader />;

  if (isValidatePersonaOpen && enablePersonaVerification) {
    return (
      <ValidatePersonaModal
        locale={localeMyProfile?.personaLocales.personaModal}
        userVerificationStatus={userVerificationStatus}
        profileData={providerProfileData}
      />
    );
  }

  return (
    <>
      <div className="mx-4 col-span-12 flex flex-col justify-center items-center desktop:col-span-9 desktop:flex-row desktop:justify-start">
        <div className="flex flex-col mt-[15px] desktop:mt-0 desktop:ml-[15 px]">
          <p className="mt-[15px] text-h6 text-med-gray">
            {localePendingReview?.welcome}
          </p>
          <h2 className="text-[28px] desktop:text-h1 text-dark-gray break-word">
            {localePendingReview?.title}
          </h2>
          <p className="mt-[15p x] text-h6 text-med-gray">
            {localePendingReview?.subtitle1}
          </p>
        </div>
      </div>
      {userVerificationStatus.status ===
        Persona_Inquiry_Status_Enum.Declined && (
        <div className="w-full mt-4">
          <Alert
            type="warning"
            customContent={
              <div className="flex desktop:flex-row desktop:justify-between desktop:items-center mobile:flex-col w-full">
                <p className="text-clc-red font-semibold desktop:w-10/12 mobile:mb-4 desktop:mb-0">
                  {localePendingReview?.notAbleToVerifyAccountRightNow}
                </p>
                <ButtonComponent
                  onClick={() => navigate(MY_PROFILE_VALIDATE_PERSONA)}
                >
                  {localePendingReview?.contactUs}
                </ButtonComponent>
              </div>
            }
          />
        </div>
      )}

      <div
        className={`flex desktop:flex-row mobile:flex-col items-start px-5 pt-5 pb-[30px] desktop:p-[30px] gap-5 bg-white rounded-10 mt-5 ${
          userVerificationStatus.status !== Persona_Inquiry_Status_Enum.Approved
            ? 'desktop:h-96'
            : ''
        }`}
      >
        {userVerificationStatus.status !==
          Persona_Inquiry_Status_Enum.Approved && (
          <>
            <div className="flex flex-col flex-1 items-start p-0 gap-2.5">
              <div className="flex flex-col desktop:flex-row w-full desktop:items-center desktop:justify-between p-0 gap-2.5">
                <Alert
                  type={'info-blue'}
                  desktopItemsAlign={'desktop:items-start'}
                  customContent={
                    <div className="flex flex-col w-full desktop:flex-row justify-between items-center desktop:relative">
                      <div className="flex flex-col w-full">
                        <p className="text-base font-semibold text-center desktop:text-start text-clc-blue">
                          {localePendingReview?.alertTitle}
                        </p>
                        <p className="text-base font-semibold text-med-gray desktop:mr-[35px]">
                          {localePendingReview?.alertText}
                        </p>
                      </div>
                    </div>
                  }
                />
              </div>
              <div className="flex flex-col justify-start gap-2.5 w-full ml-2 mt-4 desktop:items-start mobile:items-center">
                <div>
                  <h4 className="uppercase font-bold">
                    {localePendingReview?.furtherVerificationInfo.question1}
                  </h4>
                  <ul className="list-disc ml-6">
                    <li>
                      {localePendingReview?.furtherVerificationInfo.answer1}
                    </li>
                    <li>
                      {localePendingReview?.furtherVerificationInfo.answer2}
                    </li>
                  </ul>
                  <h4 className="uppercase font-bold mt-4">
                    {
                      localePendingReview?.furtherVerificationInfo
                        .benefitsOfVerification
                    }
                  </h4>
                  <ul className="list-disc ml-6">
                    <li>
                      {localePendingReview?.furtherVerificationInfo.reason1}
                    </li>
                    <li>
                      {localePendingReview?.furtherVerificationInfo.reason2}
                    </li>
                    <li>
                      {localePendingReview?.furtherVerificationInfo.reason3}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="desktop:block mobile:hidden h-full border-l-[1px] border-gray-400 mx-1" />

            <div className="desktop:hidden mobile:block w-full border-b-[1px] border-gray-400 mx-1" />
          </>
        )}
        <div className="flex flex-col flex-1 items-start p-0 gap-2.5 w-full">
          <h4 className="mt-[15px] text-h6 font-bold">
            {localePendingReview?.IDVerificationStatus}
          </h4>
          <ProviderPersonaSection
            locale={localePendingReview}
            userVerificationStatus={userVerificationStatus.status}
          />
        </div>
      </div>

      <div className="flex flex-col items-start px-5 pt-5 pb-[30px] desktop:p-[40px] gap-5 bg-white rounded-10 mt-5">
        <div className="flex flex-col w-full">
          <p className="mt-[15px] text-h6 text-med-gray mb-4">
            {localePendingReview?.subtitle2}
            <span>
              <NavLink
                className="ml-1 underline text-clc-blue font-bold"
                to={MY_ACCOUNT_PROFILE}
              >
                {localePendingReview?.hereLink}
              </NavLink>
            </span>
          </p>
          <hr className="flex w-full items-center h-px bg-black-blur" />
        </div>
        <div className="flex flex-col mt-4 w-full desktop:mx-20">
          <div className="flex flex-col items-center w-full">
            <h2 className="uppercase self-start my-4 font-bold">
              {localePendingReview?.accountInfo}
            </h2>
            {accountInfoItems.map((field, index) => (
              <div
                key={index}
                className="flex flex-col desktop:flex-row w-full desktop:ml-40 mobile:mb-6"
              >
                <div className="flex desktop:flex-row mobile:flex-col w-full">
                  <label className="w-full desktop:w-3/12 text-base font-semibold desktop:mb-2">
                    {field.label}
                  </label>
                  <div className="w-full desktop:w-6/12">
                    {Array.isArray(field.value) ? (
                      field.value?.map((item, index) => (
                        <span className="text-med-gray font-semibold">
                          {item}
                          {index <
                            (field.value as unknown as string[]).length - 1 &&
                            ', '}{' '}
                        </span>
                      ))
                    ) : field.label === localePendingReview?.phone ? (
                      <PhoneDataItem
                        country={providerParsedPhone?.country ?? ''}
                        extension={providerParsedPhone?.extension ?? ''}
                        phone={providerParsedPhone?.number ?? ''}
                      />
                    ) : field.label === localePendingReview?.profilePicture ? (
                      <div className="flex flex-row w-full desktop:w-9/12 gap-[15px]">
                        {/* //TODO: Implement upload photo for not-yet-validated providers: COD 2620 */}
                        <Avatar size="md" imageUrl={profilePicture} />
                        <div className="flex flex-row items-center cursor-pointer">
                          {isEditing ? (
                            <EditIconDisable className="w-4 h-4 fill-current mr-[7px] text-med-gray" />
                          ) : (
                            <EditIcon className="w-4 h-4 fill-current mr-[7px] text-med-gray" />
                          )}

                          <p
                            className={`text-bas font-bold underline uppercase ${
                              isEditing ? 'text-med-gray' : 'text-clc-blue'
                            }`}
                            onClick={handleUploadPhoto}
                          >
                            {localePendingReview?.changePhoto}
                          </p>
                          <input
                            type="file"
                            id="myImage"
                            onChange={handleImageUpload}
                            ref={fileInputRef}
                            className="hidden"
                          />
                        </div>
                      </div>
                    ) : (
                      <p className="text-med-gray font-semibold">
                        {field.value}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <hr className="flex items-center h-px bg-black-blur desktop:w-10/12" />
          <div className="flex flex-col items-center w-full">
            <h2 className="uppercase self-start my-4 font-bold">
              {localePendingReview?.personalInfo}
            </h2>
            {personalInfoItems.map((field, index) => (
              <div
                key={index}
                className="flex flex-col desktop:flex-row w-full desktop:ml-40 mobile:mb-6"
              >
                <div className="flex desktop:flex-row mobile:flex-col w-full">
                  <label className="w-full desktop:w-3/12 text-base font-semibold desktop:mb-2">
                    {field.label}
                  </label>
                  <div className="w-full desktop:w-6/12">
                    {Array.isArray(field.value) ? (
                      field.value?.map((item, index) => (
                        <span
                          key={index}
                          className="text-med-gray font-semibold"
                        >
                          {item as string}
                          {index < (field.value as string[]).length - 1 &&
                            ', '}{' '}
                        </span>
                      ))
                    ) : (
                      <>
                        <p className="text-med-gray font-semibold">
                          {field.value}
                        </p>
                        {field.label === localePendingReview?.aboutYou &&
                          (field.value as string).length > 450 && (
                            <ButtonComponent
                              type="underline"
                              iconPosition="right"
                              Icon={isExpanded ? ArrowSeeLess : ArrowSeeMore}
                              onClick={() => handleSetExpanded(!isExpanded)}
                            >
                              {isExpanded
                                ? localePendingReview.seeLess
                                : localePendingReview.seeMore}
                            </ButtonComponent>
                          )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <hr className="flex items-center h-px bg-black-blur desktop:w-10/12" />
          <div className="flex flex-col items-center w-full">
            <h2 className="uppercase self-start my-4 font-bold">
              {localePendingReview?.credentialsAndCertifications}
            </h2>
            {credentialsAndCertificationsItems.map((field, index) => (
              <div
                key={index}
                className="flex flex-col desktop:flex-row w-full desktop:ml-40 mobile:mb-6"
              >
                <div className="flex desktop:flex-row mobile:flex-col w-full">
                  <label className="w-full desktop:w-3/12 text-base font-semibold desktop:desktop:mb-2">
                    {field.label}
                  </label>
                  <div className="w-full desktop:w-6/12">
                    {Array.isArray(field.value) ? (
                      field.value?.map((item, index) => (
                        <span
                          key={index}
                          className="text-med-gray font-semibold"
                        >
                          {item}
                          {index < (field.value as string[]).length - 1 &&
                            ', '}{' '}
                        </span>
                      ))
                    ) : (
                      <p className="text-med-gray font-semibold">
                        {field.value}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <hr className="flex items-center h-px bg-black-blur desktop:w-10/12" />
          <div className="flex flex-col items-center w-full">
            <h2 className="uppercase self-start my-4 font-bold">
              {localePendingReview?.officeDetails}
            </h2>
            {officeDetailsItems.map((field, index) => (
              <div
                key={index}
                className="flex flex-col desktop:flex-row w-full desktop:ml-40 mobile:mb-6"
              >
                <div className="flex desktop:flex-row mobile:flex-col w-full">
                  <label className="w-full desktop:w-3/12 text-base font-semibold desktop:mb-2">
                    {field.label}
                  </label>
                  <div className="w-full desktop:w-6/12">
                    {Array.isArray(field.value) ? (
                      field.value?.map((item, index) => (
                        <span
                          key={index}
                          className="text-med-gray font-semibold"
                        >
                          {item}
                          {index < (field.value as string[]).length - 1 &&
                            ', '}{' '}
                        </span>
                      ))
                    ) : field.label === localePendingReview?.phone ? (
                      <PhoneDataItem
                        country={officeParsedPhone?.country ?? ''}
                        extension={officeParsedPhone?.extension ?? ''}
                        phone={officeParsedPhone?.number ?? ''}
                      />
                    ) : (
                      <p className="text-med-gray font-semibold">
                        {field.value}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
