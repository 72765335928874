import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';

interface ILastVisitedUsersComponent {
  isEmpty?: boolean;
  children?: React.ReactNode;
  emptyStateTitle?: string;
}

const LastVisitedUsersComponent = ({
  isEmpty,
  children,
  emptyStateTitle,
}: ILastVisitedUsersComponent) => {
  const { data: locale } = useGetPage({
    locale: 'en',
    pageId: pageIds.PROVIDERS_SELECTION,
  });

  return (
    <div className="p-5 w-full z-[1] flex flex-col items-start absolute top-[50px] bg-white rounded-large gap-px shadow-container max-h-[357px] desktop:max-h-[493px] overflow-y-auto">
      <div className="flex flex-col gap-5 w-full">
        <p className="text-charcoal-gray text-h6 font-bold">
          {locale?.lastViewedProvidersTitle}
        </p>
        {isEmpty ? (
          <p className="self-center font-medium text-sm desktop:text-h6 text-med-gray">
            {emptyStateTitle}
          </p>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default LastVisitedUsersComponent;
