import { useGetPage } from 'hooks/useGetPage';
import { genericActionsIds, pageIds } from 'utilities/constants';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up.svg';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { useGetUnauthenticatedQuestionsByIdsQuery } from 'graphql/generated/hasura';
import {
  MultiValueRemove,
  ClearIndicator,
  MultiValueLabel,
  SingleValueComponent,
  DropdownIndicator,
  NoOptionsMessage,
} from 'components/dropdown/ReactSelectComponents';
import {
  GeneralStates,
  ProviderProfileDataOptional,
  providerDataResponseEducation,
} from 'app/my-account/interfaces/profile.interfaces';
import {
  findQuestionById,
  getSelectedValues,
  parseQuestionOptionsForDropdownWithLookup,
  scrollToTop,
} from 'utilities/functions';
import ButtonComponent from 'components/button/buttonComponent';
import { usePatchFhirProviderAccountInfoByCodexIdMutation } from 'graphql/generated/remote-schema-hasura';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CodexFeatureFlags } from 'utilities/interfaces';
import {
  CredentialsErrors,
  CredentialsItem,
  EducationErrors,
  EducationListItem,
  ProviderProfileInfoProps,
  Question,
  educationFieldType,
  educationItem,
} from './interfaces';
import { questionsIds } from '../constants';
import Select, { MultiValue, SingleValue } from 'react-select';
import {
  DropdownItem,
  DropdownOption,
  DropdownOptionsState,
} from 'components/dynamicQuestionnaire/interfaces/dynamicQuestionnaireResponse.interface';
import ErrorMessageWithIcon from 'components/errorMessageWithIcon';
import { ReactComponent as PlusIcon } from 'assets/icons/plusIcon.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minusIcon.svg';
import CreatableSelect from 'react-select/creatable';

export const CredentialsAndCertifications: React.FC<
  ProviderProfileInfoProps
> = ({
  credentials,
  education,
  handleProviderProfileInfoChange,
  handleUpdatedProviderAccountInfo,
}) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { useFhirFlow } = useFlags<CodexFeatureFlags>();
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PROFILE,
  });
  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.UPDATE],
    });
  const [isFormDisplayed, setIsFormDisplayed] = useState<boolean>(true);
  const [formValues, setFormValues] = useState<ProviderProfileDataOptional>(
    () =>
      ({
        credentials,
        education,
      } as ProviderProfileDataOptional),
  );
  const [patchFhirProviderAccountInfo] =
    usePatchFhirProviderAccountInfoByCodexIdMutation({
      refetchQueries: ['GetFHIRProviderbyEmail'],
    });

  const handleShowForm = () => {
    setIsFormDisplayed(!isFormDisplayed);
  };

  const { data: questionsData, loading: questionsLoading } =
    useGetUnauthenticatedQuestionsByIdsQuery({
      variables: {
        ids: [
          questionsIds.CREDENTIALS_TITLE_QUESTION,
          questionsIds.CREDENTIALS_STATE_QUESTION,
          questionsIds.CREDENTIALS_BOARD_QUESTION,
          questionsIds.EDUCATION_SCHOOL_QUESTION,
          questionsIds.EDUCATION_RESIDENCY_QUESTION,
          questionsIds.EDUCATION_UNDERGRADUATE_QUESTION,
        ],
      },
    });

  const credentialsTitleQuestionData = (!questionsLoading &&
    questionsData &&
    findQuestionById(
      questionsData,
      questionsIds.CREDENTIALS_TITLE_QUESTION,
    )) as Question;

  const credentialsStateQuestionData = (!questionsLoading &&
    questionsData &&
    findQuestionById(
      questionsData,
      questionsIds.CREDENTIALS_STATE_QUESTION,
    )) as Question;

  const credentialsBoardQuestionData = (!questionsLoading &&
    questionsData &&
    findQuestionById(
      questionsData,
      questionsIds.CREDENTIALS_BOARD_QUESTION,
    )) as Question;

  const educationSchoolQuestionData = (!questionsLoading &&
    questionsData &&
    findQuestionById(
      questionsData,
      questionsIds.EDUCATION_SCHOOL_QUESTION,
    )) as Question;

  const educationResidencyQuestionData = (!questionsLoading &&
    questionsData &&
    findQuestionById(
      questionsData,
      questionsIds.EDUCATION_RESIDENCY_QUESTION,
    )) as Question;

  const educationUndergraduateQuestionData = (!questionsLoading &&
    questionsData &&
    findQuestionById(
      questionsData,
      questionsIds.EDUCATION_UNDERGRADUATE_QUESTION,
    )) as Question;

  const [selectedValue, setSelectedValue] = useState<DropdownOptionsState[]>([
    { value: formValues.credentials?.titles ?? [] },
    { value: formValues.credentials?.states ?? [] },
    { value: formValues.credentials?.certifications ?? [] },
  ]);

  const parseEducationList = (
    educationData: providerDataResponseEducation[],
  ) => {
    return educationData.map((education, index) => ({
      id: index,
      title: `Education List ${index + 1}`,
      completed: false,
      answers: [education],
    }));
  };

  const [educationList, setEducationList] = useState(
    parseEducationList(formValues.education ?? []),
  );

  const sortedEducationList = useMemo(() => {
    return [...educationList].sort((a, b) => (a.id ?? 0) - (b.id ?? 0));
  }, [educationList]);

  const processEducationList = useCallback((educationList: educationItem[]) => {
    return educationList.flatMap((educationItem) => {
      return educationItem.answers.map((answer) => {
        return {
          medicalSchool: answer.medicalSchool,
          residency: answer.residency,
          undergraduate: answer.undergraduate,
        };
      });
    });
  }, []);

  const handleSingleValueChange = (
    value: SingleValue<string | DropdownItem>,
    educationListID: number,
    type: educationFieldType,
  ) => {
    const selectedValue = (value?.valueOf() as { value: string }).value;
    const updatedMedicationList = educationList.map((educationItem) => {
      if (educationItem.id === educationListID && educationItem.answers) {
        // Update the answers for the matching education
        const updatedAnswers = [...educationItem.answers];

        // Check if there's an existing answer for the same type
        const existingAnswerIndex = updatedAnswers.findIndex(
          (answer) => answer[type] !== undefined,
        );

        if (existingAnswerIndex !== -1) {
          // Update the existing answer with the new response values
          const existingAnswer = updatedAnswers[existingAnswerIndex];
          updatedAnswers[existingAnswerIndex] = {
            ...existingAnswer, // Create a shallow copy of the existing answer object
            [type]: selectedValue,
          };
        } else {
          // If there is no existing answer, create a new one
          const newAnswer = {
            medicalSchool: '',
            residency: '',
            undergraduate: '',
            id: educationListID,
          };

          // Set the selected value based on the 'type'
          newAnswer[type] = selectedValue;

          // Merge the new value with the existing answer if the new value is not empty
          const existingAnswer = updatedAnswers.find(
            (answer) => answer[type] !== undefined,
          );
          if (existingAnswer && selectedValue && !existingAnswer[type]) {
            updatedAnswers[existingAnswerIndex] = {
              ...existingAnswer,
              [type]: selectedValue,
            };
          } else {
            updatedAnswers.push(newAnswer);
          }
        }
        return {
          ...educationItem,
          answers: updatedAnswers,
        };
      }

      return educationItem;
    });
    // Update the state with the modified educationList
    setEducationList(updatedMedicationList);
  };

  const insertAfter = (id: number, education: educationItem) => {
    const array = [...educationList];
    const index = educationList.findIndex((item) => item.id === id);
    array.splice(index + 1, 0, education);
    setEducationList(array);
  };

  const insertAfterLastEducation = () => {
    const lastMedication = educationList[educationList.length - 1];
    if (lastMedication && lastMedication.id !== undefined) {
      insertAfter(lastMedication.id, {
        id: educationList.length,
        title: `Education List ${educationList.length}`,
        completed: false,
        answers: [],
      });
    } else if (lastMedication === undefined) {
      insertAfter(0, {
        id: 0,
        title: 'Education List 0',
        completed: false,
        answers: [],
      });
    }
  };
  const removeEducation = (idToRemove: number) => {
    const updatedArray = educationList
      .filter((education) => education.id !== idToRemove)
      .map((education, index) => ({
        ...education,
        id: index, // Resetting the id based on the new index
        title: `Education List ${index}`,
      }));
    setEducationList(updatedArray);
  };

  const [credentialsErrors, setCredentialsErrors] = useState<CredentialsErrors>(
    {
      titles: null,
      states: null,
      certifications: null,
    },
  );

  const [educationErrors, setEducationErrors] = useState<EducationErrors[]>([
    {
      medicalSchool: null,
      residency: null,
      undergraduate: null,
    },
  ]);

  const validateCredentials = useCallback(
    (credentials: CredentialsItem | undefined) => {
      const properties = ['titles', 'states', 'certifications'];

      properties.forEach((property) => {
        if (credentials?.[property as keyof typeof credentials].length === 0) {
          setCredentialsErrors((prevState) => ({
            ...prevState,
            [property]: locale?.providerLocales.fieldRequired,
          }));
        } else {
          setCredentialsErrors((prevState) => ({
            ...prevState,
            [property]: null,
          }));
        }
      });
    },
    [locale?.providerLocales.fieldRequired],
  );

  const validateEducation = useCallback(
    (educationList: EducationListItem[]) => {
      setEducationErrors((prevState) => {
        return educationList.map((education, index) => {
          const properties = ['medicalSchool', 'residency', 'undergraduate'];
          const updatedEducation = { ...prevState[index] };

          properties.forEach((property) => {
            if (education[property as keyof typeof education].trim() === '') {
              updatedEducation[property as keyof typeof updatedEducation] =
                locale?.providerLocales.fieldRequired;
            } else {
              updatedEducation[property as keyof typeof updatedEducation] =
                null;
            }
          });

          return updatedEducation;
        });
      });
    },
    [locale?.providerLocales.fieldRequired],
  );

  useEffect(() => {
    handleProviderProfileInfoChange(formValues);
  }, [formValues, handleProviderProfileInfoChange]);

  const handleValidation = useCallback(
    (providerData: ProviderProfileDataOptional) => {
      validateEducation(providerData.education ?? []);
      validateCredentials(providerData.credentials);
    },
    [validateCredentials, validateEducation],
  );

  const prevLocalFormDataRef = useRef<ProviderProfileDataOptional | null>(null);
  const validationExecutedRef = useRef(false);

  const handleValidationCallback = useCallback(() => {
    // Check if the validation has already been executed
    if (!validationExecutedRef.current) {
      if (handleValidation) {
        handleValidation(formValues);
      }
      validationExecutedRef.current = true;
    }
  }, [formValues, handleValidation]);

  useEffect(() => {
    // Check if localFormData is different from the previous data
    if (formValues !== prevLocalFormDataRef.current) {
      // Update the ref with the latest current data
      prevLocalFormDataRef.current = formValues;

      // Reset the validation executed ref when localFormData changes
      validationExecutedRef.current = false;

      // Run validation when localFormData changes
      handleValidationCallback();
    }
  }, [formValues, handleValidationCallback]);

  const handleOnSubmit = async () => {
    const isCredentialsValid = Object.values(credentialsErrors).every(
      (value) => value === null,
    );

    const isEducationListValid = educationErrors.every((error) =>
      Object.values(error).every((value) => value === null),
    );

    const isEducationListComplete =
      educationList.length === educationErrors.length;

    const isFormValid =
      isCredentialsValid && isEducationListValid && isEducationListComplete;

    if (!isFormValid)
      return handleUpdatedProviderAccountInfo(GeneralStates.MISSING_INFO);

    try {
      setIsSubmiting(true);
      if (useFhirFlow) {
        const response = await patchFhirProviderAccountInfo({
          variables: {
            providerAccountInfo: {
              credentials: formValues.credentials,
              education: formValues.education,
            },
          },
        });
        if (!response.data) {
          setIsSubmiting(false);
          handleUpdatedProviderAccountInfo(GeneralStates.ERROR);
          scrollToTop();
          throw new Error('Failed to update user');
        }
      }

      handleUpdatedProviderAccountInfo(GeneralStates.SUCCESS);

      setIsSubmiting(false);
      scrollToTop();
    } catch (error: unknown) {
      setIsSubmiting(false);
      console.log(
        'Error when updating credentials and certifications section',
        error,
      );
      handleUpdatedProviderAccountInfo(GeneralStates.ERROR);
      scrollToTop();
      throw new Error('Failed to update user');
    }
  };

  const handleMultiValueChange = (
    e: MultiValue<DropdownOption>,
    no: number,
  ) => {
    const newItem: DropdownOptionsState[] = selectedValue.map(
      (item: DropdownOptionsState) => {
        return selectedValue.indexOf(item) === no
          ? { value: Array.isArray(e) ? e.map((x) => x.value) : [] }
          : item;
      },
    );
    // If length of array is > 1 and none option is selected, remove it
    const filteredOptions = newItem.map((item) => {
      if (
        item.value.some((value) => value.toLowerCase() === 'none') &&
        item.value.length > 1
      ) {
        return {
          ...item,
          value: item.value.filter((value) => value.toLowerCase() !== 'none'),
        };
      } else {
        return item;
      }
    });

    setSelectedValue(filteredOptions);
    setFormValues({
      ...formValues,
      credentials: {
        titles: filteredOptions[0].value,
        states: filteredOptions[1].value,
        certifications: filteredOptions[2].value,
      },
    });
  };

  const getEducationListValue = (index: number, type: educationFieldType) => {
    let parsedResponse: DropdownItem = {
      label: '',
      value: '',
    };

    const indexedQuery = educationList.findIndex((item) => {
      return item.id === index;
    });
    const indexedAnswers = educationList[indexedQuery].answers[0];

    if (indexedAnswers !== undefined) {
      switch (type) {
        case educationFieldType.MEDICAL_SCHOOL:
          parsedResponse = {
            label: indexedAnswers.medicalSchool,
            value: indexedAnswers.medicalSchool,
          };
          break;
        case educationFieldType.RESIDENCY:
          parsedResponse = {
            label: indexedAnswers.residency,
            value: indexedAnswers.residency,
          };
          break;
        case educationFieldType.UNDERGRADUATE:
          parsedResponse = {
            label: indexedAnswers.undergraduate,
            value: indexedAnswers.undergraduate,
          };
          break;
        default:
          console.error('An unexpected error happened.');
          break;
      }
    } else {
      return undefined;
    }
    return parsedResponse;
  };

  const getEducationDataForIndex = useMemo(() => {
    return (index: number, type: educationFieldType) => {
      const education = educationList?.find((item) => item.id === index);

      return (
        education &&
        education.answers?.length &&
        education?.answers[0][type].length > 1
      );
    };
  }, [educationList]);

  useEffect(() => {
    setFormValues((prevFormValues) => {
      const processedEducation = processEducationList(educationList);
      return {
        ...prevFormValues,
        education: processedEducation,
      };
    });
  }, [educationList, processEducationList]);

  if (
    loading ||
    !locale ||
    genericActionLoading ||
    !genericAction ||
    questionsLoading
  )
    return null;

  return (
    <div className="flex flex-col items-center bg-white w-full p-5 desktop:p-[30px] gap-12 rounded-10">
      <div
        className="flex flex-col items-start w-full gap-2.5 p-0 cursor-pointer desktop:cursor-default"
        onClick={handleShowForm}
      >
        <div className="flex flex-row w-full items-center justify-between">
          <div className="flex flex-row items-center">
            <h3 className="text-h5 desktop:text-h4 text-charcoal-gray font-exo font-semibold desktop:mr-2.5">
              {locale?.providerLocales?.credentialsAndCertificationsSection}
            </h3>
          </div>
          {isFormDisplayed ? (
            <ChevronUpIcon
              className="desktop:hidden w-[18px] h-2.5 fill-current cursor-pointer"
              onClick={handleShowForm}
            />
          ) : (
            <ChevronDownIcon
              className="desktop:hidden w-[18px] h-2.5 fill-current cursor-pointer"
              onClick={handleShowForm}
            />
          )}
        </div>
        <hr
          className={`desktop:flex flex-row w-full items-center h-px bg-black-blur ${
            isFormDisplayed ? 'flex' : 'hidden'
          }`}
        />
      </div>

      <div
        className={`w-full desktop:flex flex-col gap-[15px] ${
          isFormDisplayed ? 'flex' : 'hidden'
        }`}
      >
        <p className="text-h5 text-charcoal-gray font-bold uppercase mb-[15px]">
          {locale?.providerLocales?.credentialsTitle}
        </p>
        <div className="flex flex-col w-full max-w-[750px] mx-auto">
          <div className="flex flex-col w-full desktop:w-[750px] mb-[35px]">
            <div className="flex flex-col desktop:flex-row">
              <div className="desktop:flex-row desktop:text-right desktop:ml-auto desktop:mr-4">
                <label className="justify-between w-full text-base text-charcoal-gray font-semibold mb-2">
                  {credentialsTitleQuestionData.text}
                  <span className="text-base font-bold text-clc-red">
                    {locale?.accountDeletion.form.required}
                  </span>
                </label>
                <p className="w-full desktop:max-w-[150px] text-sm text-med-gray font-semibold">
                  {credentialsTitleQuestionData.subtitle}
                </p>
              </div>
              <div className="w-full desktop:max-w-[435px]">
                <Select
                  className="dropdown"
                  classNamePrefix={
                    credentialsErrors.titles
                      ? 'react-select-with-error'
                      : 'react-select'
                  }
                  placeholder={locale?.selectLanguagePlaceholder}
                  value={getSelectedValues(
                    parseQuestionOptionsForDropdownWithLookup(
                      credentialsTitleQuestionData.answers,
                    ),
                    selectedValue[0].value,
                  )}
                  options={parseQuestionOptionsForDropdownWithLookup(
                    credentialsTitleQuestionData.answers,
                  )}
                  onChange={(event) => {
                    return handleMultiValueChange(event, 0);
                  }}
                  components={{
                    MultiValueLabel,
                    MultiValueRemove,
                    ClearIndicator,
                    DropdownIndicator,
                    NoOptionsMessage,
                  }}
                  isMulti
                  noOptionsMessage={() => locale?.selectLanguageNoOptions}
                />
                {credentialsErrors.titles && (
                  <ErrorMessageWithIcon message={credentialsErrors.titles} />
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full desktop:w-[750px] mb-[35px]">
            <div className="flex flex-col desktop:flex-row">
              <div className="desktop:flex-row desktop:text-right desktop:ml-auto desktop:mr-4">
                <label className="justify-between w-full text-base text-charcoal-gray font-semibold mb-2">
                  {credentialsStateQuestionData.text}
                  <span className="text-base font-bold text-clc-red">
                    {locale?.accountDeletion.form.required}
                  </span>
                </label>
                <p className="w-full ml-auto desktop:max-w-[150px] text-sm text-med-gray font-semibold">
                  {credentialsStateQuestionData.subtitle}
                </p>
              </div>
              <div className="w-full desktop:max-w-[435px]">
                <Select
                  className="dropdown"
                  classNamePrefix={
                    credentialsErrors.states
                      ? 'react-select-with-error'
                      : 'react-select'
                  }
                  placeholder={locale?.selectLanguagePlaceholder}
                  value={getSelectedValues(
                    parseQuestionOptionsForDropdownWithLookup(
                      credentialsStateQuestionData.answers,
                    ),
                    selectedValue[1].value,
                  )}
                  options={parseQuestionOptionsForDropdownWithLookup(
                    credentialsStateQuestionData.answers,
                  )}
                  onChange={(event) => {
                    return handleMultiValueChange(event, 1);
                  }}
                  components={{
                    MultiValueLabel,
                    MultiValueRemove,
                    ClearIndicator,
                    DropdownIndicator,
                  }}
                  isMulti={true}
                  noOptionsMessage={() => locale?.selectLanguageNoOptions}
                />
                {credentialsErrors.states && (
                  <ErrorMessageWithIcon message={credentialsErrors.states} />
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full desktop:w-[750px] mb-[35px]">
            <div className="flex flex-col desktop:flex-row">
              <div className="desktop:flex-row desktop:text-right desktop:ml-auto desktop:mr-4">
                <label className="justify-between w-full text-base text-charcoal-gray font-semibold mb-2">
                  {credentialsBoardQuestionData.text}
                  <span className="text-base font-bold text-clc-red">
                    {locale?.accountDeletion.form.required}
                  </span>
                </label>
                <p className="w-full desktop:max-w-[150px] text-sm text-med-gray font-semibold">
                  {credentialsBoardQuestionData.subtitle}
                </p>
              </div>
              <div className="w-full desktop:max-w-[435px]">
                <CreatableSelect
                  className="dropdown"
                  classNamePrefix={
                    credentialsErrors.certifications
                      ? 'react-select-with-error'
                      : 'react-select'
                  }
                  placeholder={locale?.selectCreateEntry}
                  value={getSelectedValues(
                    parseQuestionOptionsForDropdownWithLookup(
                      credentialsBoardQuestionData.answers,
                    ),
                    selectedValue[2].value,
                  )}
                  options={undefined}
                  onChange={(event) => {
                    return handleMultiValueChange(event, 2);
                  }}
                  components={{
                    MultiValueLabel,
                    MultiValueRemove,
                    ClearIndicator,
                    DropdownIndicator,
                  }}
                  isMulti={true}
                  noOptionsMessage={() => locale?.selectCreateEntry}
                />
                {credentialsErrors.certifications && (
                  <ErrorMessageWithIcon
                    message={credentialsErrors.certifications}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <hr />

        <p className="text-h5 text-charcoal-gray font-bold uppercase mb-[15px]">
          {locale?.providerLocales?.certificationTitle}
        </p>

        <div className="flex flex-col w-full max-w-[750px] mx-auto">
          {sortedEducationList?.map((educationItem, index) => {
            return (
              <div key={educationItem.id}>
                <div className="flex flex-col w-full desktop:w-[750px] mb-[35px]">
                  <div className="flex flex-col items-baseline desktop:flex-row">
                    <div className="desktop:flex-row desktop:text-right desktop:ml-auto desktop:mr-4">
                      <label className="justify-between w-full text-base text-charcoal-gray font-semibold mb-2">
                        {educationSchoolQuestionData.text}
                        <span className="text-base font-bold text-clc-red">
                          {locale?.accountDeletion.form.required}
                        </span>
                      </label>
                      <p className="w-full desktop:max-w-[150px] text-sm text-med-gray font-semibold">
                        {educationSchoolQuestionData.subtitle}
                      </p>
                    </div>
                    <div className="w-full desktop:max-w-[435px]">
                      <CreatableSelect
                        className="dropdown"
                        classNamePrefix={
                          educationErrors &&
                          educationErrors[educationItem.id] &&
                          educationErrors[educationItem.id].medicalSchool
                            ? 'react-select-with-error'
                            : 'react-select'
                        }
                        placeholder={locale?.selectCreateEntry}
                        value={
                          getEducationDataForIndex(
                            educationItem.id,
                            educationFieldType.MEDICAL_SCHOOL,
                          )
                            ? getEducationListValue(
                                educationItem.id,
                                educationFieldType.MEDICAL_SCHOOL,
                              )
                            : { label: '', value: '' }
                        }
                        options={undefined}
                        onChange={(event) => {
                          return handleSingleValueChange(
                            event,
                            educationItem.id,
                            educationFieldType.MEDICAL_SCHOOL,
                          );
                        }}
                        components={{
                          SingleValue: SingleValueComponent,
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          //@ts-ignore
                          DropdownIndicator,
                        }}
                        isMulti={false}
                        noOptionsMessage={() => locale?.selectCreateEntry}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full desktop:w-[750px] mb-[35px]">
                  <div className="flex flex-col items-baseline desktop:flex-row">
                    <div className="desktop:flex-row desktop:text-right desktop:ml-auto desktop:mr-4">
                      <label className="justify-between w-full text-base text-charcoal-gray font-semibold mb-2">
                        {educationResidencyQuestionData.text}
                        <span className="text-base font-bold text-clc-red">
                          {locale?.accountDeletion.form.required}
                        </span>
                      </label>
                      <p className="w-full desktop:max-w-[150px] text-sm text-med-gray font-semibold">
                        {educationResidencyQuestionData.subtitle}
                      </p>
                    </div>
                    <div className="w-full desktop:max-w-[435px]">
                      <CreatableSelect
                        className="dropdown"
                        classNamePrefix={
                          educationErrors &&
                          educationErrors[educationItem.id] &&
                          educationErrors[educationItem.id].residency
                            ? 'react-select-with-error'
                            : 'react-select'
                        }
                        placeholder={locale?.selectCreateEntry}
                        value={
                          getEducationDataForIndex(
                            educationItem.id,
                            educationFieldType.RESIDENCY,
                          )
                            ? getEducationListValue(
                                educationItem.id,
                                educationFieldType.RESIDENCY,
                              )
                            : { label: '', value: '' }
                        }
                        options={undefined}
                        onChange={(event) => {
                          return handleSingleValueChange(
                            event,
                            educationItem.id,
                            educationFieldType.RESIDENCY,
                          );
                        }}
                        components={{
                          SingleValue: SingleValueComponent,
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          //@ts-ignore
                          DropdownIndicator,
                        }}
                        isMulti={false}
                        noOptionsMessage={() => locale?.selectCreateEntry}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full desktop:w-[750px] mb-[35px]">
                  <div className="flex flex-col items-baseline desktop:flex-row">
                    <div className="desktop:flex-row desktop:text-right desktop:ml-auto desktop:mr-4">
                      <label className="justify-between w-full text-base text-charcoal-gray font-semibold mb-2">
                        {educationUndergraduateQuestionData.text}
                        <span className="text-base font-bold text-clc-red">
                          {locale?.accountDeletion.form.required}
                        </span>
                      </label>
                      <p className="w-full desktop:max-w-[150px] text-sm text-med-gray font-semibold">
                        {educationUndergraduateQuestionData.subtitle}
                      </p>
                    </div>
                    <div className="w-full desktop:max-w-[435px]">
                      <CreatableSelect
                        className="dropdown"
                        classNamePrefix={
                          educationErrors &&
                          educationErrors[educationItem.id] &&
                          educationErrors[educationItem.id].undergraduate
                            ? 'react-select-with-error'
                            : 'react-select'
                        }
                        placeholder={locale?.selectCreateEntry}
                        value={
                          getEducationDataForIndex(
                            educationItem.id,
                            educationFieldType.UNDERGRADUATE,
                          )
                            ? getEducationListValue(
                                educationItem.id,
                                educationFieldType.UNDERGRADUATE,
                              )
                            : { label: '', value: '' }
                        }
                        options={undefined}
                        onChange={(event) => {
                          return handleSingleValueChange(
                            event,
                            educationItem.id,
                            educationFieldType.UNDERGRADUATE,
                          );
                        }}
                        components={{
                          SingleValue: SingleValueComponent,
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          //@ts-ignore
                          DropdownIndicator,
                        }}
                        isMulti={false}
                        noOptionsMessage={() => locale?.selectCreateEntry}
                      />
                    </div>
                  </div>
                </div>
                {educationList.length > 1 && index >= 1 && (
                  <div className="flex pt-2 pb-6">
                    {
                      <button
                        className="flex mr-6"
                        onClick={() => removeEducation(educationItem.id)}
                      >
                        <MinusIcon className="text-clc-blue mr-2" />
                        <span className="text-clc-blue font-bold underline uppercase">
                          {locale?.providerLocales?.removeEducation}
                        </span>
                      </button>
                    }
                  </div>
                )}
                {educationList.length > 1 && <hr className="mb-4" />}
              </div>
            );
          })}
        </div>

        <hr />

        <div className="hidden w-full desktop:flex justify-between">
          <ButtonComponent
            type="underline"
            Icon={PlusIcon}
            iconPosition="left"
            stroke="transparent"
            className="text-sm font-bold font-exo"
            onClick={insertAfterLastEducation}
          >
            {locale?.providerLocales?.addMoreEducation}
          </ButtonComponent>

          <ButtonComponent
            disabled={isSubmiting}
            className="px-10 text-sm font-bold font-exo"
            onClick={handleOnSubmit}
          >
            {genericAction?.[genericActionsIds.UPDATE].update}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};
