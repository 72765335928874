import DefaultPatient from 'assets/images/defaultPatient.svg';
import { twMerge } from 'tailwind-merge';
type Size = 'xs' | 'sm' | 'sm_s' | 'sm_md' | 's' | 'md' | 'lg';
interface AvatarProps {
  imageUrl?: string | null;
  size: Size;
  customClasses?: string;
  children?: React.ReactNode | null;
}

const SizeOption = {
  xs: 'h-6 min-w-[1.5rem] w-6',
  sm: 'h-9 w-9 min-w-9',
  sm_s: 'h-[48px] w-[48px] min-w-[48px]',
  sm_md: 'h-[52px] w-[52px] min-w-[52px]',
  s: 'h-20 w-20 min-w-20',
  md: 'h-[106px] w-[106px]',
  lg: 'h-40 w-40 min-w-40',
};

const getSizeClasses = (size: Size): string => {
  return SizeOption[size] || SizeOption.sm;
};

const Avatar: React.FC<AvatarProps> = ({ imageUrl, size, customClasses }) => {
  const baseClasses = 'rounded-[200px]';

  return (
    <img
      className={twMerge(
        `${baseClasses} ${getSizeClasses(size)} object-cover`,
        customClasses,
      )}
      src={imageUrl ? imageUrl : DefaultPatient}
      alt="Avatar"
    />
  );
};

export default Avatar;
