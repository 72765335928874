import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { WarningIcon } from 'assets/icons/WarningIcon';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as InfoNewIcon } from 'assets/icons/info-new.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/notificationClose.svg';
import IconComponent from './iconComponent';
import ButtonComponent from './button/buttonComponent';
import React from 'react';
import { twMerge } from 'tailwind-merge';

export type AlertType =
  | 'positive'
  | 'warning'
  | 'info-new'
  | 'info-blue'
  | 'info'
  | 'notification';

interface AlertProps {
  type: AlertType;
  text?: string;
  textClassName?: string;
  iconClassName?: string;
  customContent?: React.ReactNode;
  testID?: string;
  isNotification?: boolean;
  actionLabel?: string;
  hideCloseButton?: boolean;
  extraActions?: React.ReactNode;
  enableAutoFittingOfExtraActions?: boolean;
  desktopItemsAlign?: string;
  onActionClick?: () => void;
  onClose?: () => void;
}

const Alert: React.FC<AlertProps> = ({
  type,
  text,
  customContent,
  textClassName,
  testID,
  actionLabel,
  isNotification = false,
  hideCloseButton = false,
  extraActions,
  enableAutoFittingOfExtraActions = undefined,
  desktopItemsAlign,
  onActionClick,
  onClose,
  iconClassName,
}) => {
  let bgClass;
  let textClass;
  let fillClass;
  let icon;
  let buttonType;

  switch (type) {
    case 'positive':
      bgClass = 'bg-positive';
      textClass = 'text-alert-positive';
      fillClass = 'fill-none';
      icon = CheckIcon;
      buttonType = 'underline';
      break;
    case 'warning':
      bgClass = 'bg-negative';
      textClass = 'text-alert-negative';
      fillClass = 'fill-alert-negative';
      icon = WarningIcon;
      buttonType = 'underline';
      break;
    case 'info-new':
      bgClass = 'bg-white';
      textClass = 'med-gray';
      fillClass = 'med-gray';
      icon = InfoNewIcon;
      break;
    case 'info-blue':
      bgClass = 'bg-light-blue';
      textClass = 'med-gray';
      fillClass = 'med-gray';
      icon = InfoNewIcon;
      break;
    case 'info':
      bgClass = 'bg-yellow';
      textClass = 'text-alert-yellow';
      fillClass = 'fill-alert-yellow';
      icon = InfoIcon;
      break;
    default:
      bgClass = 'bg-positive';
      textClass = 'text-alert-positive';
      fillClass = 'fill-none';
      icon = CheckIcon;
  }
  return (
    <div
      data-testid={testID}
      className={`relative flex flex-col desktop:flex-row w-full ${
        desktopItemsAlign ? desktopItemsAlign : 'desktop:items-center'
      } items-center p-5 desktop:px-5 desktop:py-3 gap-[15px] rounded-10 ${bgClass}`}
    >
      <IconComponent
        width="w-12 desktop:w-6"
        height="h-12 desktop:h-6"
        icon={icon}
        fill={fillClass}
        className={iconClassName}
      />
      <div className="flex flex-col grow desktop:flex-row items-center desktop:items-start p-0 desktop:py-[2px] desktop:pr-[10px] shrink">
        {customContent ? (
          <>{customContent}</>
        ) : (
          <p
            className={twMerge(
              'text-base font-semibold text-center desktop:text-start',
              textClass,
              textClassName,
            )}
          >
            {text}
          </p>
        )}
      </div>
      {isNotification && (
        <>
          <div
            className={`desktop:flex desktop:flex-row gap-4 shrink-0 items-center ${
              enableAutoFittingOfExtraActions ||
              customContent ||
              (enableAutoFittingOfExtraActions === undefined &&
                (extraActions || actionLabel))
                ? 'desktop:justify-end'
                : 'desktop:justify-center desktop:w-1/2'
            } `}
          >
            {(actionLabel || extraActions) && (
              <>
                {actionLabel && (
                  <ButtonComponent
                    type={buttonType ?? ''}
                    iconPosition="right"
                    onClick={onActionClick}
                    className="shrink-0"
                  >
                    {actionLabel}
                  </ButtonComponent>
                )}
                {extraActions}
              </>
            )}
            {!hideCloseButton && (
              <CloseIcon
                className={
                  'stroke-med-gray w-5 h-5 cursor-pointer ml-auto hidden desktop:block'
                }
                onClick={onClose}
              />
            )}
          </div>
          {!hideCloseButton && (
            <CloseIcon
              className={
                'absolute stroke-med-gray w-5 h-5 top-4 right-4 cursor-pointer desktop:hidden'
              }
              onClick={onClose}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Alert;
