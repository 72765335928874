import { ReactComponent as SearchIcon } from 'assets/icons/searchIcon.svg';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import ButtonComponent from 'components/button/buttonComponent';
import InputComponent from 'components/inputComponent';
import useDropdown from 'components/dropdown/useDropdown';
import LastVisitedUsersComponent from 'components/LastVisitedUsersComponent/LastVisitedUsersComponent';
import { useGetLastViewedProvidersByUserQuery } from 'graphql/generated/remote-schema-hasura';
import LastVisitedUserItem from 'components/LastVisitedUsersComponent/LastVisitedUserItem';
import { Roles } from '../../../firebase/interfaces';

const DROPDOWN_ID = 'find-provider';

interface ProviderFilterProps {
  search: string | null;
  setCurrentFilter: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
  onClick: () => void;
}

export const ProviderFilter: React.FC<ProviderFilterProps> = ({
  search,
  setCurrentFilter,
  onClick,
}) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.PROVIDERS_SELECTION,
  });

  const { data, loading: isDataLoading } = useGetLastViewedProvidersByUserQuery(
    { fetchPolicy: 'network-only' },
  );

  const lastViewedProviders =
    data?.getLastViewedProvidersByUser?.providers ?? [];

  const {
    openDropdownId,
    handleDropdownClick,
    registerDropdownRef,
    handleHoverOut,
  } = useDropdown();

  const handleFocus = () => {
    if (!search) {
      handleDropdownClick(DROPDOWN_ID, true);
    }
  };

  if (loading || !locale || isDataLoading) return null;

  return (
    <div className="flex flex-col w-full py-4 px-8 gap-4 bg-base-content rounded-10">
      <p className="font-semibold text-dark-gray text-h5">
        {locale?.filters?.title}
      </p>
      <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
      <div className="flex flex-col desktop:flex-row w-full justify-between gap-8">
        <div className="flex flex-col desktop:flex-row gap-4 desktop:items-center w-full desktop:w-full">
          <p className="text-dark-gray font-semibold text-h6">
            {locale?.filters?.find}
          </p>
          <div
            ref={(ref) => registerDropdownRef(DROPDOWN_ID, ref)}
            className="w-full relative"
          >
            <InputComponent
              noMarginBottom
              type="text"
              name="find"
              maxLengthValue={35}
              decorator={<SearchIcon />}
              decoratorLeft
              value={search || ''}
              onChange={(e) => {
                setCurrentFilter((prev) => ({
                  ...prev,
                  search: e.target.value,
                }));

                if (!e.target.value) {
                  handleDropdownClick(DROPDOWN_ID);
                } else {
                  handleHoverOut();
                }
              }}
              onFocus={handleFocus}
              disableAutocomplete
            />

            {openDropdownId === DROPDOWN_ID && (
              <LastVisitedUsersComponent
                isEmpty={!lastViewedProviders.length}
                emptyStateTitle={locale?.noLastViewedProviders}
              >
                {lastViewedProviders.map((provider) => {
                  return (
                    <LastVisitedUserItem
                      key={provider.id}
                      userId={provider.id}
                      name={`${provider.SENSITIVE_firstname} ${provider.SENSITIVE_lastname}`}
                      title={provider.SENSITIVE_credentials.titles[0]}
                      profileImage={
                        provider?.SENSITIVE_profile_picture_id ?? ''
                      }
                      buttonText={locale?.viewProviderProfile}
                      role={Roles.PROVIDER}
                      signUrl
                    />
                  );
                })}
              </LastVisitedUsersComponent>
            )}
          </div>
        </div>
        <div className="hidden flex-col desktop:flex-row gap-4 desktop:items-center w-full desktop:w-[30%]">
          <p className="text-dark-gray font-semibold text-h6 desktop:w-20">
            {locale?.filters?.sortBy}
          </p>
          <div className="w-full">
            <InputComponent
              type="select"
              name="sortBy"
              selectInputProps={{ fullWidth: true }}
            />
          </div>
        </div>
      </div>
      <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
      <div className="flex flex-col desktop:flex-row w-full justify-between desktop:justify-end items-center p-0 gap-[30px] mt-2.5">
        <div className="flex flex-row w-full desktop:w-auto items-end">
          <ButtonComponent type="outlined" onClick={onClick}>
            {locale?.filters?.button}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};
