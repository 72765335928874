class StorageService {
  key: string;
  defaultValue: null | unknown;
  private storage: Storage;

  constructor(
    key: string,
    storage = localStorage,
    defaultValue: null | unknown = null,
  ) {
    this.key = key;
    this.storage = storage;
    this.defaultValue = defaultValue;
  }

  addData(data: object) {
    const current = this.getData() || {};
    return this.setData({ ...current, ...data });
  }

  setData(data: unknown) {
    const newData = JSON.stringify(data);
    return this.storage.setItem(this.key, newData);
  }

  getData() {
    const item = this.storage.getItem(this.key);
    if (item) {
      return JSON.parse(item);
    }
    return null;
  }

  get value() {
    const item = this.storage.getItem(this.key);
    if (item) {
      return JSON.parse(item);
    }
    return this.defaultValue;
  }

  set value(value) {
    this.storage.setItem(this.key, JSON.stringify(value));
    return;
  }

  clear() {
    this.storage.removeItem(this.key);
  }
}

export default StorageService;
