import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { ReactComponent as MessageIcon } from 'assets/icons/message.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/pwEyeShow.svg';
import { ReactComponent as CalendarCancelIcon } from 'assets/icons/calendarCancel.svg';
import { ReactComponent as ResendIcon } from 'assets/icons/resend.svg';
import { genericActionsIds } from 'utilities/constants';
import { AppointmentTypeEnum } from '../../../graphql/generated/hasura';

export enum AppointmentType {
  video = 0,
  chat,
  message,
}

export const AppointmentTypeByHasuraEnum = {
  [AppointmentTypeEnum.Email]: AppointmentType.message,
  [AppointmentTypeEnum.Chat]: AppointmentType.chat,
  [AppointmentTypeEnum.Video]: AppointmentType.video,
  [AppointmentTypeEnum.Dnavisit]: undefined,
};

export enum UpcomingAppointmentAction {
  View,
  Cancel,
  Resend,
}

export interface ActionRenderInfo {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  genericActionId: number;
  genericActionTextField: string;
}

export const appointmentActionRenderInfo: Record<
  UpcomingAppointmentAction,
  ActionRenderInfo
> = {
  [UpcomingAppointmentAction.View]: {
    icon: EyeIcon,
    genericActionId: genericActionsIds.VIEW,
    genericActionTextField: 'view',
  },
  [UpcomingAppointmentAction.Cancel]: {
    icon: CalendarCancelIcon,
    genericActionId: genericActionsIds.CANCEL,
    genericActionTextField: 'cancel',
  },
  [UpcomingAppointmentAction.Resend]: {
    icon: ResendIcon,
    genericActionId: genericActionsIds.MESSAGING,
    genericActionTextField: 'messaging',
  },
} as const;

export const defaultUpcomingAppointmentsActions: UpcomingAppointmentAction[] = [
  UpcomingAppointmentAction.View,
  UpcomingAppointmentAction.Cancel,
];

export interface UpcomingAnointmentType {
  date: string;
  provider: string;
  type: AppointmentType;
  actions?: UpcomingAppointmentAction[];
  id: string;
}

export const appointmentRenderInfo: Record<
  AppointmentType,
  {
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    text: string;
  }
> = {
  [AppointmentType.video]: {
    icon: CameraIcon,
    text: 'videoTypeText',
  },
  [AppointmentType.chat]: {
    icon: ChatIcon,
    text: 'chatTypeText',
  },
  [AppointmentType.message]: {
    icon: MessageIcon,
    text: 'messagingTypeText',
  },
} as const;

export interface UpcomingAppointmentsLocale extends Record<string, string> {
  viewAppointmentsHistory: string;
  upcomingAppointmentsTitle: string;
  upcomingAppointmentsSubtitle: string;
}

export interface UpcomingAppointmentsProps {
  locale: UpcomingAppointmentsLocale;
  upcomingAppointments: UpcomingAnointmentType[];
  count?: Record<string, number>;
}

export interface UpcomingAppointmentsRowProps {
  locale: UpcomingAppointmentsLocale;
  appointment: UpcomingAnointmentType;
  onActionClicked: (action: UpcomingAppointmentAction) => void;
}

export interface UpcomingAppointmentsRowActionProps {
  action: UpcomingAppointmentAction;
  appointmentId?: string;
}
